import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost86 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #86" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Gabby Walker, Enterprise Technology Software Developer
              at Lendlease
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>March 15, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              As a UTS IT Co-op Scholar, Gabby has found her love for software
              and creative freedom in UX design and frontend web development.
              Her discoveries in the tech industry arose from making the most of
              her opportunities at her internship experiences in WiseTech Global
              and Lendlease, and her time at university. This week, we will
              learn about what inspired her to start a career in STEM, and share
              the skills and aspirations that shaped her to lead a successful
              early career.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              During high school I really struggled to figure out what exactly I
              wanted to study at university. While I found maths and science to
              be the easiest and most enjoyable subjects, I also was extremely
              interested in the creative side of things, e.g. music and art. I
              actually ended up starting a Bachelor of Architecture, thinking
              that it would be a good combination of both creativity and logical
              thinking. However, I promptly figured out that this was not for
              me. After some suggestions from career advisors at university, I
              delved into the idea of software engineering. As someone who still
              wasn’t sure what I wanted, I liked the sound of a degree that
              would provide the opportunities to work in many different fields
              to help me figure it all out. Now that I have almost finished my
              degree, I can say I have 0 regrets. I have found my niche in UX
              design which I think perfectly balances critical and creative
              thinking.
            </p>
            <br />

            <p className={styles.question}>
              Tell me about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              I am really proud of how much I have achieved in the last 4 years
              as I had zero experience in coding and technology in high school.
              As a Women in Engineering and IT Co-Op scholar I have had the
              fantastic opportunity to complete a few internships whilst
              studying at UTS. Through these internships I have gained
              experience as a systems engineer at Cuscal and a software engineer
              at Wisetech Global. I then remained at WiseTech as a casual
              employee, however I moved to a Product Associate role as I had
              developed an interest in the user experience side of software
              development. I have recently started a new internship at Lendlease
              as a Software Developer focusing on front-end website design. The
              most challenging part so far is the imposter syndrome as I am
              always surrounded by extremely talented people. I do have to
              constantly remind myself that I am only at the beginning of my
              career, and I still have so much time to learn and grow just like
              those around me have already.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              Melanie Perkins is the Co-Founder and CEO of Canva. Her story of
              studying communications at university before changing career paths
              and becoming an entrepreneur at 19 years old inspires me greatly.
              She has worked extremely hard to become one of the only 2% of
              female CEOs of venture-backed companies and is always encouraging
              people from diverse backgrounds to pursue big dreams. This is
              something I try to focus on as I make my way through my career.
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              The STEM industry has been overpowered by men for such a long time
              and has created this narrative that women cannot/should not be
              involved in it. As such, women are more hesitant to join. However,
              as more women are involved in the industry there is a greater
              support system being created which in turn should entice more
              women to join. Young females will have role models in the industry
              to look up to and products will be created that are designed for
              women by women! All of these factors reduce the intimidation that
              many women fear in the industry and will hopefully change
              society's perception of STEM, which is a step towards a more
              inclusive and diverse industry!
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              In my area of product design and development, I would say that
              soft skills are more important than hard skills. You must be able
              to communicate well, both written and verbal, so that you can
              express your ideas clearly to anyone regardless of their technical
              understanding. You must also make sure that you are proactive and
              comfortable asking questions when you get stuck. Most importantly,
              you should also ensure that you work well with others as most of
              the time you will be working in a team.
            </p>
            <p className={styles.postContent}>
              In saying that, it is good to have a solid technical background to
              support you throughout your career. Focus on understanding the
              fundamentals of coding and then branch towards your niche language
              (mine is Javascript). From a design perspective, try and learn the
              principles of design and start practising wireframes and
              prototypes on Figma or Adobe Illustrator.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              Coffee and responsibilities. Joking… Definitely the idea that each
              day I get to learn something new and grow as a person. Whether
              this is related to work/study or just general life I don’t really
              care, just as long as it can help me and potentially those around
              me.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              Since I’m still studying this is directed towards my first-year
              self: Don’t worry that you don’t have any idea about what you are
              doing with your life. No one does. Enjoy the process of figuring
              it all out, try new things and make some great memories. Work and
              life will eventually figure itself out, it still hasn’t yet but
              things are getting clearer.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>No rain, no flowers.</p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "chelsea-chaffey": [
              "/portraits/blog-authors/chelsea-chaffey1.PNG",
              styles.authorProfile,
              "Chelsea Chaffey",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost86;
