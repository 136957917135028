import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost67 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #67" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Carmen Leong, Senior Lecturer at UNSW Business School
              and Ph.D. Scholar of Information Systems
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>July 28, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              As a Ph.D. Information Systems Scholar who has achieved multiple
              accolades in the field of Information Technology, Carmen Leong has
              garnered many versatile experiences in her journey to academia
              success. From being a software analyst in Malaysia to a public
              servant in Singapore, it is empowering to catch a glimpse of
              Carmen’s everlasting career attributions towards the education
              sector where she is constantly striving to support upcoming
              younger generations and undertaking research works.
            </p>
            <p className={styles.postContent}>
              Being an inspirational STEM figure who has accomplished many
              things along the way, we are extremely excited to share our
              interview insights with Carmen on her unwavering determination and
              dedication in academic research as well as her immense passion for
              technology!
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              Because technology is everywhere! and the younger me thought it
              should allow me to know about many other industries.
            </p>
            <br />

            <p className={styles.question}>
              Please provide a short summary of your career journey so far:
            </p>

            <p className={styles.postContent}>
              I have been a software analyst in Malaysia, a management trainee
              and a public servant in Singapore and I am glad that I am in the
              education sector being a lecturer and researcher who can continue
              to explore how technology make changes in organizations and
              society.{" "}
            </p>
            <br />

            <p className={styles.question}>
              Where do you see yourself in the future?
            </p>

            <p className={styles.postContent}>
              I would love to make a contribution in academia, supporting the
              younger generation to shape their future career and to continue
              bring relevance in my research.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What do you love about your career now?
            </p>

            <p className={styles.postContent}>
              My career now allows me the space for a lot of exploration and
              creativity when I think of a research and how to make my teaching
              more effective and engaging.{" "}
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?{" "}
            </p>

            <p className={styles.postContent}>
              I think women have the natural advantage of being meticulous and
              patient and these are the traits required to make advancement in
              STEM.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the challenging aspects of your career?
            </p>

            <p className={styles.postContent}>
              Technology is so broad and there are so many different pathways
              you can take - frontend, backend, full-stack, data science,
              product management, UI/UX just to name a few. I had a lot of
              different interests and that is why it is really important to take
              some time at university exploring internships, programs, courses
              and most importantly talking to people in the industry to better
              understand the role and the field. Through this, you can figure
              out what works for you and decide if this role will be a good fit
              for your interest and your set of skills.{" "}
            </p>
            <br />

            <p className={styles.question}>
              How have others inspired you at different periods throughout your
              career?
            </p>

            <p className={styles.postContent}>
              I have been encouraged by so many people - my father who has
              unconditional trust that I can excel as a person, mentor who
              inspire me with their dedication to work and colleagues who care
              for others' success and wellbeing.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              This may sound ironic but I went through it myself. I would
              encourage people who are considering a PhD to have a few years of
              industry experience. I believe that to study something, you need
              to have "seen" it through your own eyes.{" "}
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              You can be a better leader than you think, if you care enough for
              others.{" "}
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto? </p>

            <p className={styles.postContent}>
              That would be UNSW motto: Scientia Manu et Mente – Knowledge by
              hand and mind{" "}
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "erika-shi": [
              "/portraits/blog-authors/erika-shi.png",
              styles.anonProfile,
              "Erika Shi",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost67;
