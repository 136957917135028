import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";
import GoogleAnalytics from "react-ga";

class blogPost90 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #90" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Jennifer Garcia, Acting Team Leader of Digital
              Forensics at the Australian Federal Police
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>April 13, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Committed to maintaining the safety of our Australian community,
              Jennifer Garcia is a role model who has applied STEM skills to
              bring positive and life-changing impacts to society. Beginning as
              a technical officer at the Australian Police Force (AFP), Jennifer
              is now working as an Acting Team Leader of Digital Forensics. She
              holds multiple honorary achievements including her contributions
              to saving over 50 children in her Child Protection roles. Much
              like the ABC and Marie Claire Magazine, UNSW Women in Technology
              also has the honour to interview Jennifer and feature her in this
              week’s WIT Crush Wednesday. Read more to explore Jennifer’s career
              and the advice she gives to university students.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              One of the main reasons I joined the field of STEM was because of
              my upbringing and time I spent in South America. As a child, I
              could see crime was everywhere and the communities across our
              cities were extremely unsafe. I knew at a very young age that I
              wanted to be part of an organisation that contributed to
              protecting people and communities. I had a deep desire to help
              people. I knew the only way I could achieve this was through
              education. I was the first person in my family to graduate
              university in the field of Forensics, majoring in Chemistry. When
              you educate yourself, you empower yourself with the ability to
              contribute to change.
            </p>
            <br />

            <p className={styles.question}>
              Tell us about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              I commenced in the AFP and as technical officer, I had aspirations
              to be a Crime Scene Investigator, and only a few years later won
              the position of CSI. This was something I had worked towards my
              whole adult life. Once I was in the role, I just felt like it
              wasn't a good fit for me.
            </p>

            <p className={styles.postContent}>
              The work was wonderful, mainly drug related but the environment
              itself was not one I was thriving in. I craved that connection to
              the people, people I knew out there who needed our help. That led
              me to discover a whole other world within Forensics, Digital
              Forensics. I was amazed at the calibre of work they produced and
              the immediate effect and impact that their work had. I could see
              this was definitely going to be a huge part of the future of
              Forensics and eventually I transferred across and haven’t looked
              back. I contributed to numerous Child Protection jobs where we
              achieved some amazing results collectively. This was some of the
              hardest and most confronting work I had ever done, but at the same
              time equally as rewarding. It culminated with an interview and
              media article with the ABC and Marie Claire magazine, where I
              featured in both articles due to my contribution as a Digital
              Forensic Examiner on a job called Operation ARKSTONE, this job
              contributed to saving over 50 children and still counting.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              My family, I have to say hands down- the sacrifices they made to
              always give us the best education above anything else, makes me
              eternally grateful. My parents arrived in Australia in the 70's at
              a very young age, they only had $100 in their pocket and did not
              know the language, but their work ethic, drive and integrity
              allowed them to build an amazing life. My mother had this saying
              growing up that always stuck with me{" "}
              <i>
                "Nunca serás capaz de cruzar el océano hasta que pierdas de
                vista la costa"
              </i>{" "}
              which translates to "you can never cross an ocean until you have
              the courage to lose sight of the shore" and she was right.
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              As women I believe we have always asked politely for a seat at the
              table, I believe we should be paid to take that seat. We have so
              much to contribute as women, there's a grace that women bring to
              everything they do in life, but don't mistake that grace or
              kindness for weakness. It is not. I believe as women we have the
              ability to be innovative and see the bigger picture but with so
              much heart. When you're passionate about what you're doing the sky
              is really the limit. Bringing these qualities (yourself) into the
              world of STEM will allow you to achieve a success that goes beyond
              the tangible.
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              I believe the hard skills are obvious, you need a tertiary
              qualification in the Sciences, volunteer work within the science
              communities and anything else that looks great on paper and might
              make you stand out from a group of other applicants, but the soft
              skills we rarely give them the value they deserve. The hard skills
              will get you that foot in the door you need, the soft skills are
              what will make you a leader in the field. These skills give us
              insight into who people truly are, how they will operate in a team
              environment and how they will cope with imperfect situations.
              People tend to build the foundation of their leadership skills
              upon their soft skills so it's really important that we not only
              place value on tertiary qualifications and technical skills but
              also what people bring to the table as humans.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              My passion for my job and the people I work with, my husband, my
              step daughter and my family. Our work isn't done at the end of
              every successful project or when a job goes to resolution
              successfully. It's all those little things you do every day that
              matter. I am motivated by the smallest things in life, they are
              the building blocks to achieving the great.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university, and why?
            </p>

            <p className={styles.postContent}>
              Don't worry about what everyone else is doing, pave your own path
              and walk the journey of life on your terms. Believe that who you
              are as a person is enough and everything else you bring to the
              table is just complementary to you.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>
              "People won't remember what you said, or what you did, but they
              will always remember how you made them feel" At the end of the day
              we are just people trying to find our joy in life, be kind to
              everyone especially those who challenge you, they truly need it
              the most.
            </p>
            <br />
  
            <p className={styles.postContent}>
              {" "}
              <GoogleAnalytics.OutboundLink
                eventLabel="blog-post-90-AFP-Jobs"
                to="https://jobs.afp.gov.au/"
                target="_blank"
              >
                Outsmart crime with a career at the AFP
              </GoogleAnalytics.OutboundLink>{" "}
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "nusaibah-bari": [
              "/portraits/blog-authors/nusaibah-bari.jpg",
              styles.authorProfile,
              "Nusaibah Bari",
              "Publications Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost90;
