import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost85 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #85" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Rosanne Sukkar, Dean of Enterprise & Innovation at
              Santa Sabina College
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>March 9, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              While pursuing her joint passions in technology and education as
              an Information and Software Technology teacher, Rosanne has
              inspired many female students to pursue a career in STEM. She is
              committed to making her classroom a dynamic learning environment,
              and inspiring her students to approach technology with curiosity,
              open-mindedness and compassion. With over 20 years of teaching
              experience, Rosanne is also the current Dean of Enterprise and
              Innovation at the prestigious Santa Sabina College. This week, we
              will explore Rosanne’s experience in the education sector, sharing
              her challenges and successes, and how she motivates young women to
              become socially responsible technologists.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              I was enrolled in a Computer Science course at University 28 years
              ago, there weren't many female students in the course. I wasn't
              confident enough to stay in the course so I transferred to
              teaching where I majored in teaching computing studies. When I
              started teaching at an all girls school I became an advocate for
              girls in STEM, I try to build their confidence and encourage girls
              to pursue careers in the STEM field. I often tell my junior
              students that learning to code or having the ability to use
              technology to solve problems is like having a super power. I run a
              coding club to encourage students to participate inorder to
              develop their computational skills. I love teaching in this area
              because it is never static, technology is always changing, we are
              always innovating, there is never a dull day.
            </p>
            <br />

            <p className={styles.question}>
              Tell me about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              I started working at Santa Sabina College 20 years ago, I often
              get asked why I have stayed at a school for this amount of time. I
              am so fortunate and privileged to be working at a school that
              encourages students to be young women of conviction, to have
              courage and to strive confidently into the future with a positive
              vision for themselves and their world. As a teacher I understand
              that I have a responsibility to my students to ensure they
              understand the impact they can have on one another and on the
              wider community. Everyday is different, challenging and rewarding.
            </p>
            <p className={styles.postContent}>
              Over the years I have tried to provide dynamic learning
              experiences through applying a range of learning principles
              including growth mindset, complexity and challenge. One of my
              memorable moments at Santa Sabina was when my IPT class all
              participated in the AI Challenge for Good, with all students
              reaching the NSW finalists list. One group came 1st in NSW and
              represented the state in the Nationals, reaching a respectable 3rd
              place overall.
            </p>
            <p className={styles.postContent}>
              Another proud teacher moment was when two students in my Year 12
              HSC IPT class placed 2nd and 3rd in the state. Approximately 19%
              of the IPT candidates are girls, to receive these results was a
              win for girls in tech.
            </p>
            <p className={styles.postContent}>
              My biggest challenge is to change the mindset of young girls, as
              they often assume that studying computing is all about code. I am
              trying to teach students that when they take up a STEM course it
              is about problem solving and improving the world they live in.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              I have worked at a school with amazing mentors, who have
              indirectly taken me under their wings. There is so much power in
              women empowering other women through mentorship. My School
              Principal and Head of Department saw my potential and made me
              believe that I had the ability to really make a difference. This
              inspired me to think big, to try and provide the same opportunity
              for students in my care. I must also mention Sr Sheila Flynn, who
              is the Director of Formation at Dominican Schools, Melbourne &
              Sydney, Australia. She is an advocate for women living in poverty
              in South Africa. Her work inspires me to make a difference, as a
              teacher we are responsible for shaping our students. Social
              justice and service is so important. I often encourage my students
              to think globally, How can we use technology to improve our world?
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              To produce better outcomes with innovative solutions to real-world
              problems, it is important to have diversity. We live in a world
              with increasing AI technology. It is paramount that these systems
              we are developing incorporate diversity. Having a woman’s
              perspective is crucial to developing fair and just systems.
            </p>
            <p className={styles.postContent}>
              ​​Without the presence of women in the field of STEM, innovation
              will be limited and exclude half of the population. We need more
              women in STEM to further innovation and better represent the needs
              of society.
            </p>
            <p className={styles.postContent}>
              I've been teaching for over 20 years and I have noticed a decline
              in girls' engagement in STEM, this often happens around 12-14
              years of age.
            </p>
            <p className={styles.postContent}>
              ​​This is not the sole responsibility of teachers, we are all
              responsible to ensure our young girls continue to be passionate
              about STEM. We need corporations, government and individuals to
              support the education sector to develop programs that are relevant
              and engaging.
            </p>
            <p className={styles.postContent}>
              We need to work together as a collective group to solve this
              problem. For any organisations reading this article, What can YOU
              do to bridge the gender gap in STEM?
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              I try to teach my students to be compassionate, they are so
              privileged to get an amazing education at Santa Sabina. As a
              school community we encourage our students to be involved in
              social justice and service initiatives. In my classroom I try to
              ensure my students use this knowledge and passion to solve
              problems in the areas of accessibility and humanitarian action
              through the integration of technology. We try to build skills in
              altruism, open-mindedness, responsibility and curiosity, be
              outward-looking and future-focused. We encourage every student to
              have balance, to get involved in community and co-curricular, as a
              result this helps students build their time management skills.
            </p>
            <p className={styles.postContent}>
              In regards to hard skills, I always manage to integrate a little
              bit of coding, AI or HTML in whatever I am delivering. For example
              if I am supporting an English Teacher in delivering a multimodal
              unit, I can guarantee somehow integrating a little HTML. I believe
              every student should know a little bit of code and HTML. These are
              essential skills for their future.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              Firstly, my own family, I have two beautiful daughters who are
              growing up so quickly. Secondly, my students inspire me. Our
              students are creative and innovative, it's very inspiring when you
              see the students' big ideas. They are not cynical about their
              world, they are positive, passionate and so bright eyed, I get to
              experience this everyday I walk into my classroom. Seeing a
              student's face light up because they have been introduced to some
              new and emerging technology or when they have solved their
              computer code on their own. This all motivates me to get up and to
              be a part of their journey. As an educator, I am part of something
              big, I have a responsibility to ensure my students are critical
              thinkers, passionate and problem solvers. Our students will face
              global challenges including climate change, food security and
              pandemics. We are educating the next generation of problem solvers
              and critical thinkers. It is motivating to think I am a part of
              shaping these young minds.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              Have courage, confidence and take more risks. It's ok to fail, by
              failing you build resilience knowledge and you truly learn more
              about what you are capable of.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>There is no script for life.</p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "yuhan-zhou": [
              "/portraits/blog-authors/yuhan.jpg",
              styles.authorProfile,
              "Yuhan Zhou",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost85;
