import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";
import GoogleAnalytics from "react-ga";

class blogPost77 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #77" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Chemistry and Computers – The Powerful Duo
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>November 5, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              When you think about chemistry, your mind probably conjures up
              images of beakers, chemicals and toxic hazard signs. However, have
              you ever considered the winning combination of chemistry and
              computers? There is a particularly interesting field in STEM that
              combines these two components to solve real world problems –
              <em>
                <b> Computational Chemistry</b>
              </em>
              .
            </p>
            <br />

            <p className={styles.question}>What is it?</p>

            <p className={styles.postContent}>
              Computational chemistry is the use of computer modelling and
              simulation to study the structures and properties of molecules,
              then applying these to real chemical problems. The computational
              techniques and methods are also improved and developed over time
              to get better approximations of any structure and property we’re
              looking for in materials. For example, it can be used to predict
              the vibrational frequencies of molecules, thermodynamic
              quantities, spectroscopic quantities and more! We often perform
              these calculations on supercomputers, solving complex equations
              such as the electronic Schrodinger equation, to describe natural
              phenomena.
            </p>
            <br />

            <p className={styles.question}>
              Some current applications and key fields of research
            </p>

            <p className={styles.postContent}>
              The advancements of computational chemistry over the past two
              decades have led to more ground-breaking research and applications
              in our lives. They can be used to:
              <ul>
                <li className={styles.list}>
                  Look for{" "}
                  <GoogleAnalytics.OutboundLink
                    eventLabel="blog-post-77-alien"
                    to="https://newsroom.unsw.edu.au/news/science-tech/scientists-shed-more-light-molecules-linked-life-other-planets"
                    target="_blank"
                  >
                    alien
                  </GoogleAnalytics.OutboundLink>{" "}
                  life by investigating the biosignatures in the solar system
                  and exoplanets{" "}
                </li>
                <li className={styles.list}>
                  Search for the origins of life and homochirality through
                  exploring the pre-biotic and chiral molecules in the
                  interstellar medium
                </li>
                <li className={styles.list}>
                  Enhance the design of new solar energy capture technologies
                  that can store and transport energy more efficiently with
                  minimal impact on the environment
                </li>
                <li className={styles.list}>
                  Study how different gases can be greenhouse gases by examining
                  simulated infrared spectra
                </li>
                <li className={styles.list}>
                  Provide a rationale on why chlorofluorocarbons are an
                  ozone-depleting chemical, and insights into environmentally
                  friendly alternatives via the predicted chemical and physical
                  properties
                </li>
              </ul>
            </p>
            <br />

            <p className={styles.question}>
              Ok, so how does computational chemistry further ‘traditional’
              chemistry?
            </p>

            <p className={styles.postContent}>
              Theory, modelling and simulation can enhance our understanding of
              the known systems, from which we can make better predictions.
              Computational calculations can take shorter time to perform than
              the actual experiments. So, they can be used to provide
              qualitative and quantitative insights into experimental work,
              guiding the experimental process or designing new systems that are
              more efficient. Computational calculations can also help simulate
              results, meaning that we can just visualise and avoid doing
              experiments that are too difficult or too dangerous to be
              performed. These simulations are less expensive since no actual
              chemicals, machines and equipment are involved. So you don’t need
              to be concerned about anything as extreme as a chemical explosion!
              Phew!
            </p>
            <br />

            <p className={styles.postContent}>
              If you are interested to learn more about computational chemistry,
              check out Dr Laura McKemmish’s introductory{" "}
              <GoogleAnalytics.OutboundLink
                eventLabel="blog-post-77-dr-laura-mckemmish-videos"
                to="https://sites.google.com/view/laurakmckemmish/research/introductory-videos?authuser=0"
                target="_blank"
              >
                videos.
              </GoogleAnalytics.OutboundLink>{" "}
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "sue-vo": [
              "/portraits/blog-authors/sue-vo.png",
              styles.anonProfile,
              "Sue Vo",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost77;
