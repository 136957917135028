import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost92 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #92" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Zhuo (Zoey) Chen, Computer Science PhD student at the
              University of Melbourne
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>April 20, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Passionate about empowering women, Zhuo Chen is a fierce advocate
              for gender equality in STEM careers. With vast experience in 
              research fields, Zhuo chronicles her journey to success by
              balancing academics, extracurriculars and self care. She also
              teaches us about the importance of seizing opportunities, and to
              live a life with no regrets. We learn about Zhuo’s experiences with
              internships, research roles, and university tutoring. Reflecting on
              the experiences of the esteemed Ada Lovelace, Zhuo inspires
              contemporary women in STEM to collaborate together and to create an
              egalitarian environment in the field.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              I initially started to pay more attention to STEM because of
              comments made by adults around me. They’d say things like "most
              of the top students in your school are girls now, but soon that
              will change because STEM courses will get harder and male
              students will peak instead." I remember being really mad about
              the fact that these adults are just prematurely determining our
              futures for us girls. I’d think to myself, “why would I be bad
              at more difficult STEM content just because I'm a girl? Why are
              guys' brains considered more superior when it comes to STEM
              studies?” The idea of fighting that gender stereotype had then
              encouraged me into pursuing a career in STEM.

            </p>
            <br />

            <p className={styles.question}>
              Tell us about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              My core passion lies within research. I have focused on doing
              research since the beginning of my undergraduate studies, by
              doing research projects, working as a research assistant at ANU
              and also taking a research internship at Data61. Following this,
              I came to UNSW to pursue my PhD and transferred to UniMelb a
              year later. I subsequently did some tutoring at ANU and UNSW,
              which was so fun and fulfilling. 
            </p>

            <p className={styles.postContent}>
              It was initially hard to get into research, and the fact that I
              just transferred from actuarial studies to computer science after
              my first year of Bachelor's just made it that much harder. Since
              I was one year behind everyone, there were a lot of times where I
              felt quite confused and like I was falling behind. This also made
              me struggle to balance life with studies. I remember bringing my
              laptop to parties and catching up with my assignment there,
              because I wanted to have fun but also wanted to survive through
              that course. It was hard to adapt to a whole new field, but it
              was all worth it in the end and I don't regret it at all.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              My biggest inspiration is Ada Lovelace. Lovelace was a female
              mathematician who wrote the first computer program, and thus was
              also considered the very first computer programmer. Lovelace was
              determined and a pioneer in her field. Even now, it is hard for a
              female to get recognised in STEM, not to mention the
              discriminatory conditions in the 19th century. She was working in
              such a difficult environment, and yet still worked towards such a
              momentous achievement. Her experience inspires me as a fellow
              female STEM student to strive towards significant accomplishments
              as well.
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              The majority of the STEM industry has always been filled with men
              due to historical ideologies and issues, making women a minority
              in this industry. It is easy for women to feel left out and find
              it hard to look for help. Therefore, it is essential to empower
              women, increase their involvement in the STEM industry and
              support them to stay. In this way, we can build a more healthy
              and friendly working environment for all individuals. This will
              further help the growth of the STEM industry.
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              For people who are interested in doing research, it is important
              to work towards achieving good grades as well as lots of research
              experience. Attending summer schools and doing summer internships
              will help a lot in developing the experience desired by
              academics/potential supervisors. It is also important to develop
              communication skills, as you will need to work with other people
              a lot when you are doing research. This will also enable you to
              build a network of connections, further allowing you to get
              access for career or study opportunities.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              A good meal! It is important to take care of yourself - rest well
              and eat well. To balance a successful career, one must maintain a
              healthy lifestyle with a good work-life balance. I am also
              motivated through hobbies and socialising, I cherish my time when
              learning new things and when talking to friends.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university, and why?
            </p>

            <p className={styles.postContent}>
              If you want to try something out, just do it. I missed out on
              some opportunities back then because I was worrying too much
              about whether I was capable, feeling overwhelmed by imposter
              syndrome. I wish I had the courage to just do it, but it has now
              taught me to just seize the opportunity as it arrives!
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>
              “When you give up, that’s when the game is over.” — Mitsuyoshi
              Anzai (Slam Dunk)
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "aarya-nair": [
              "/portraits/blog-authors/aarya-nair.jpg",
              styles.authorProfile,
              "Aarya Nair",
              "Publications Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost92;
