import React, { useEffect, useState } from "react";
import PageHeader from "../components/header";
import ".././style.css";
import styles from "./videos.module.css";
import YouTubeSubscribe from "./youtubeSubscribe";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import axios from "axios";
import humps from "humps";
import PaginationComp from "../components/Pagination";
import LoadingScreen from "../components/LoadingScreen";
import { isMobile } from "react-device-detect";

const Videos = (props) => {
  const [video, setVideo] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [videoNumber, setVideoNumber] = useState("0");
  const [sourceLoading, setSourceLoading] = useState(true);
  const [headerLoading, setHeaderLoading] = useState(true);
  // set how many posts to view per page
  const postsPerPage = 9;
  const [selectedPosts, setSelectedPosts] = useState([]);
  // current page number
  const [currentPage, setCurrentPage] = useState(1);

  // retrieve current video content
  const handleVideoNumber = (numVideos) => {
    let url = window.location.href.split("/");
    if (url[url.length - 1] && url[url.length - 1] !== "videos") {
      setVideoNumber(url[url.length - 1]);
      return url[url.length - 1];
    }
    return `${+numVideos - 1}`;
  };

  // load the page content for the current video
  const loadPageContent = (allVideos, currVideoNumber) => {
    var videoIndex = 0;
    const currVideo = allVideos.filter((video, index) => {
      if (video.videoNumber.toString() === currVideoNumber) {
        videoIndex = index;
        return true;
      } else {
        return false;
      }
    })[0];
    setVideo(currVideo);

    return videoIndex;
  };

  // load video previews
  const loadVideoPreviews = (allVideos, videoIndex) => {
    let sortedVideos = allVideos.slice(0, videoIndex).reverse();
    const additionalVideos = allVideos
      .slice(videoIndex + 1, allVideos.length)
      .reverse();
    sortedVideos = [...additionalVideos, ...sortedVideos];
    setVideos(sortedVideos.slice(0, postsPerPage));
    setCurrentPage(1);
    setSelectedPosts(sortedVideos);
  };

  // get videos
  // input: videos data from google sheets
  // output: array of dictionaries containing videos data
  useEffect(() => {
    const fetchVideos = async () => {
      setLoading(false);

      const res = await axios.get("https://wit-database.herokuapp.com/videos");
      const allVideos = humps.camelizeKeys(res.data);
      const currVideoNumber = handleVideoNumber(allVideos.length);

      if (allVideos.length <= 0 || currVideoNumber > allVideos.length) {
        props.history.push("/404");
        return;
      }

      var videoIndex = loadPageContent(allVideos, currVideoNumber);
      loadVideoPreviews(allVideos, videoIndex);

      setLoading(false);
      setSourceLoading(false);
    };

    // Start at the top of the page
    window.scrollTo(0, 0);

    // Importing Video Details
    fetchVideos().catch((error) =>
      // error handling
      console.error(error)
    );
  }, [videoNumber, props.history]);

  // called when pagination item clicked to slice the correct amount of videos for viewing
  const paginate = (pageNumber) => {
    setVideos(
      selectedPosts.slice(
        (pageNumber - 1) * postsPerPage,
        pageNumber * postsPerPage
      )
    );
    setCurrentPage(pageNumber);
  };

  // change youtube theme depending on user dark/light mode
  let youtubetheme = "full"
  if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
    youtubetheme = "dark"
  }

  // get video items
  const getVideos = (videos) => {
    return videos.map((video, index) => {
      return (
        <div className={styles.videoDescription} key={index}>
          <Link
            to={`/media/videos/${video.videoNumber}`}
            onClick={() => setVideoNumber(video.videoNumber)}
          >
            <div className={styles.darkOverlay} />
            <div className={styles.previewContainer}>
              <img
                className={styles.videoImages}
                src={process.env.PUBLIC_URL + `/videos/${video.img}`}
                alt={video.name}
              />
            </div>
            <p className={styles.moreName}>{video.name}</p>
            <p className={styles.moreDate}>{video.date}</p>
          </Link>
        </div>
      );
    });
  };

  return (
    <div>
      {sourceLoading && headerLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {/* Cover Photo */}
          <PageHeader
            imageLoading={setHeaderLoading}
            imgUrl="/headers/videos-header.jpg"
            title="Videos"
          />
          {loading && (
            <div id={styles.videoLoadingContainer}>
              <CircularProgress
                variant="indeterminate"
                size={50}
                thickness={5}
                id={styles.videoLoading}
              />
            </div>
          )}
          {/* Start of Videos */}
          {!loading && (
            <div className={styles.videosBody}>
              <h1 className={styles.vidSubheading}>Welcome to our channel</h1>
              <img
                className={styles.youtubeImg}
                src={process.env.PUBLIC_URL + "/videos/youtube.png"}
                alt="Youtube"
              />
              <p className={styles.desc}>
                Join us on our YouTube channel to keep up to date with WIT’s
                latest events, training and development videos + some extra
                behind the scene clips!
              </p>
              {/* Subscribe Button */}
              <p className={styles.subscribeText}>SUBSCRIBE HERE</p>
              {/*https://www.youtube.com/channel/UCQ8PGe3P4ZuDSNCb9vCeTiw?sub_confirmation=1&feature=subscribe-embed-click*/}
              <YouTubeSubscribe
                channelid={"UCQ8PGe3P4ZuDSNCb9vCeTiw"}
                theme={youtubetheme}
                layout={"full"}
                count={"hidden"}
                className={styles.subscribe}
              />
              {/* YouTube Embedded Video */}
              <div id={styles.videoContainer} className={styles.youtubeContainer}>
                <div className={styles.iframeWrapper}>
                  <div className={styles.responsiveIframe}>
                    <iframe
                      src={`https://youtube.com/embed/${video.youtubeVideoId}`}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen={true}
                      title={!video.name ? "Video" : video.name}
                      className={styles.embeddedVideo}
                    />
                  </div>
                  <p className={styles.videoName}>{video.name}</p>
                  <p className={styles.videoDate}>{video.date}</p>
                </div>
              </div>
              {/* See more videos */}
              <p className={styles.subHeading}>More From WIT</p>
              <div className={styles.videoContainer}>
                {isMobile ? getVideos(selectedPosts) : getVideos(videos)}
              </div>
            </div>
          )}
          {/* Pagination */}
          {!isMobile && (
            <PaginationComp
              totalPages={Math.ceil(selectedPosts.length / postsPerPage)}
              paginate={paginate}
              page={currentPage}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Videos;
