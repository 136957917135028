import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./events.module.css";
import PageHeader from "../components/header";
import Timeline from "../components/Timeline";
import CircularProgress from "@material-ui/core/CircularProgress";
import ScrollUpBtn from "../components/ScrollUpBtn";
import LoadingScreen from "../components/LoadingScreen";
import axios from "axios";
import humps from "humps";
import UpcomingEvent from "./UpcomingEvent";
import PaginationComp from "../components/Pagination";
import { isMobile } from "react-device-detect";
import { marks, valueToYear } from "./data/eventData";

const Events = () => {
  const [year, setYear] = useState(valueToYear[100]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState({
    term1: [],
    term2: [],
    term3: [],
  });
  const [sourceLoading, setSourceLoading] = useState(true);
  const [loadingPast, setLoadingPast] = useState(true);
  const [loadingUpcoming, setLoadingUpcoming] = useState(true);
  const [headerLoading, setHeaderLoading] = useState(true);

  // set how many posts to view per page
  const postsPerPage = 3;
  // current page number
  const [currentPage, setCurrentPage] = useState(1);
  // all the posts of the selected filter category
  const [selectedPosts, setSelectedPosts] = useState([]);
  // the posts displayed on the current page
  const [currentPosts, setCurrentPosts] = useState([]);

  // set the year for the events timeline
  const handleYear = (newYear) => {
    setYear(newYear);
  };

  const setTerms = (events) => {
    setPastEvents({
      term1: events.filter((event) => event.term === 1),
      term2: events.filter((event) => event.term === 2),
      term3: events.filter((event) => event.term === 3),
    });

    setLoadingPast(false);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentPosts(
      selectedPosts.slice(
        (pageNumber - 1) * postsPerPage,
        pageNumber * postsPerPage
      )
    );
  };

  // start webpage at the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get past events
  // input: past events data from google sheets
  // output: array of dictionaries containing past events data
  useEffect(() => {
    // show loading signs for past events
    setLoadingPast(true);
    const fetchPastEvents = async () => {
      const res = await axios.get(
        "https://wit-database.herokuapp.com/past-events"
      );
      const allEvents = humps
        .camelizeKeys(res.data)
        .filter((event) => event.year === Number(year));
      setTerms(allEvents.reverse());
    };
    fetchPastEvents().catch((error) =>
      // error handling
      console.error(error)
    );
  }, [year]);

  // get upcoming events
  // input: upcoming events data from google sheets
  // output: array of dictionaries containing upcoming events data
  const fetchUpcomingEvents = async () => {
    const res = await axios.get(
      "https://wit-database.herokuapp.com/upcoming-events"
    );
    const tempEvents = humps.camelizeKeys(res.data);
    setUpcomingEvents(tempEvents);
    setCurrentPosts(tempEvents.slice(0, postsPerPage));
    setSelectedPosts(tempEvents);
    setLoadingUpcoming(false);
    setSourceLoading(false);
  };

  // load upcoming events
  useEffect(() => {
    setLoadingUpcoming(true);
    fetchUpcomingEvents().catch((error) =>
      // error handling
      console.error(error)
    );
  }, []);

  // get events for a specific term
  const getTermEvents = (events) => {
    return events.map((event, index) => {
      let eventLabel = event.img.split(".")[0].split("-");
      eventLabel.shift();
      return (
        <div className={styles.pastEvent} key={index}>
          <Link to={`/event-recaps/${year}/${event.eventNumber}`}>
            <div className={styles.darkOverlay} />
            <img
              className={styles.eventImages}
              src={`${process.env.PUBLIC_URL}/event-covers/${year}/${event.img}`}
              alt={eventLabel.join(" ")}
            />
          </Link>
        </div>
      );
    });
  };

  return (
    <div>
      {sourceLoading && headerLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {/* Cover Photo */}
          <PageHeader
            imageLoading={setHeaderLoading}
            imgUrl="/headers/events-header.jfif"
            title="Events"
          />

          {/* UPCOMING EVENTS */}
          <div className={styles.eventsBody}>
            <h2>UPCOMING EVENTS</h2>
            <div className={styles.eventsLoadingContainer}>
              {loadingUpcoming && (
                <CircularProgress
                  variant="indeterminate"
                  size={50}
                  thickness={5}
                  id={styles.eventsLoading}
                />
              )}
            </div>
            {!loadingUpcoming &&
              (!upcomingEvents.length ? (
                <p className={styles.lookout}>
                  Keep a lookout here for our upcoming events!
                </p>
              ) : (
                <div className={styles.upcomingEventsContainer}>
                  {!isMobile &&
                    currentPosts.map((upcomingEvent, index) => {
                      return (
                        <div className={styles.upcomingEventsBox}>
                          <UpcomingEvent
                            upcomingEvent={upcomingEvent}
                            index={index}
                          />
                        </div>
                      );
                    })}

                  {isMobile &&
                    upcomingEvents.map((upcomingEvent, index) => {
                      return (
                        <UpcomingEvent
                          upcomingEvent={upcomingEvent}
                          index={index}
                        />
                      );
                    })}
                </div>
              ))}

            {!isMobile && (
              <PaginationComp
                totalPages={Math.ceil(selectedPosts.length / postsPerPage)}
                paginate={paginate}
                page={currentPage}
                size="large"
              />
            )}

            {/* PAST EVENTS */}
            <h2>PAST EVENTS</h2>
            <Timeline
              margin={"3%"}
              page={"events"}
              step={50}
              valueToYear={valueToYear}
              marks={marks}
              updateYear={handleYear}
            />

            <div className={styles.eventsLoadingContainer}>
              {loadingPast && (
                <CircularProgress
                  variant="indeterminate"
                  size={50}
                  thickness={5}
                  id={styles.eventsLoading}
                />
              )}
            </div>

            <div className={styles.pastEventsContainer}>
              {!loadingPast &&
                Object.keys(pastEvents)
                  .reverse()
                  .map((key) => {
                    const numEvents = React.Children.count(getTermEvents(pastEvents[key]))
                    if (numEvents > 0) {
                      return (
                        <div key={key}>
                          <h3>TERM {key.replace("term", "")}</h3>
                          <div className={styles.gridContainer}>
                            {getTermEvents(pastEvents[key])}
                          </div>
                        </div>
                      );
                    } else {
                      return (null);
                    }
                  })}
            </div>
            <ScrollUpBtn />
          </div>
        </>
      )}
    </div>
  );
};

export default Events;
