import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost88 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #88" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Cecilia Su, Diagnostic Radiographer Student at the
              University of Sydney
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>March 30, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Committed to making positive impacts on other people’s lives,
              Cecilia is currently a fourth year Diagnostic Radiography student
              at the University of Sydney. With experience working across
              private and public clinics, Cecilia has refined her skills across
              a range of fascinating diagnostic technologies including: x-ray
              machines, C-arm, fluoroscopy and computed tomography (CT). In this
              week’s WCW, Cecilia shares with us her appreciation for continuous
              learning, and the guidance of her mentors. She discusses why she
              loves her career in applied health technologies and the challenges
              and successes that she had along the way.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              Diagnostic radiography is primarily involved with the health
              industry, but it also involves the integration of different
              sciences to produce diagnostic images, which can ultimately help
              patients. I have always had a deep interest in working in STEM,
              because it is an avenue through which I can make positive impacts
              to people's lives. Through diagnostic radiography, I am able to
              work closely with technologically advanced equipment and
              applications of chemistry, physics to support the medical
              diagnosis process. As technology grows to become more efficient
              and effective, we develop greater power to improve people’s lives,
              driving my passion to work in STEM.
            </p>
            <br />

            <p className={styles.question}>
              Tell us about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              In my career journey thus far, I have had the opportunity to work
              with many experienced radiographers in different radiology sites,
              ranging from private clinics to public hospitals. I was able to
              learn and improve my radiology techniques and gain new
              perspectives from other health workers, practitioners and students
              from different health fields.
            </p>

            <p className={styles.postContent}>
              There were many challenging moments, but they only served to
              broaden my perspectives and strengthen my adaptability to changing
              environments. One of the most challenging moments in my journey is
              patient presentations and understanding adaptive techniques to
              obtain a diagnostic image. Especially when moving to different
              workplaces, equipment can come from different sources or brands,
              and thus it can be difficult to adapt to the change. My proudest
              moment would be when I am able to skillfully apply the skills I
              have learnt as a radiographer and support patients in need. No
              matter how big or small the action is, knowing that I have made a
              positive impact on a patient is an extremely rewarding moment.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              The people that have inspired me the most in my career path thus
              far are other radiographers working in private hospitals or
              clinics. These radiographers have shown tremendous passion
              teaching students and other radiographers the benefits of imaging
              patients but with lower doses of radiation. An important message I
              learnt was ALARA, which means "as low as reasonably achievable".
              This message stuck with me throughout my placements, and it is
              something that I am motivated to work on when imaging patients. It
              helps to ensure that I am not only practising techniques to an
              optimal standard, but also a safer one as well.
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              With a growing demand for STEM in the job market, it is crucial
              that we empower women to join and become active contributors to
              this field. For example, if it weren’t for Marie Curie, we
              wouldn’t have been able to advance technology to a level where
              radiation can be used for health and medical diagnosis. Women of
              the past have made remarkable contributions to the STEM industry,
              and we, as women of the present, should bring our creativity and
              technological skills to improve the world as well. In the journey
              to make our world a better place, both men and women should work
              together to innovate and contribute to the field of STEM.
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              One hard skill I recommend to diagnostic radiography students is
              knowing how to use different radiography technology and
              understanding how to manipulate the physics behind radiation to
              adapt to different patient presentations. Over time, you will
              develop skills in using standard X-Ray machines, C-arm,
              fluoroscopy, computed tomography (CT) and mobile x-ray machines.
              What we come to realise is that radiography isn’t limited to one
              piece of technology, but rather it involves multiple technologies
              each with their own unique purposes.
            </p>
            <p className={styles.postContent}>
              In terms of soft skills, improving on your communication,
              teamwork, organisation skills, and critical decision-making skills
              will help you become a more confident and proficient practitioner.
              Communication is also especially important, not only to connect to
              other radiographers, but also to patients as well. We need to
              understand patients’ needs and wants to help them.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              Knowing that there is still so much to learn about the health
              sector, and knowing that what I learn today, can be used to
              improve other people’s lives tomorrow, is very motivating for me,
              not just as a student but also as a person. I have also been
              extremely lucky to have been surrounded by an amazing group of
              supportive radiographers at each placement site I have been to.
              Having someone who is willing to teach and engage with students
              definitely makes me feel very excited about the journey to being a
              professional radiologist.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university, and why?
            </p>

            <p className={styles.postContent}>
              One piece of advice I would give to my younger self is to really
              appreciate what I was taught. Being able to understand basic
              principles is really beneficial and important because they form
              the foundations of how we approach different problems. No two
              patients are ever the same, so we need to become adaptable and
              versatile. Continuous learning is very important to me, even when
              I graduate. There is always more to learn, so you should never
              take your knowledge for granted.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>
              Learning never exhausts the mind.
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "yuhan-zhou": [
              "/portraits/blog-authors/yuhan.jpg",
              styles.authorProfile,
              "Yuhan Zhou",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost88;
