import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost69 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #69" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Nina Faridshaygan, Solution Analysis Manager at
              WooliesX
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>September 22, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Nina Faridshaygan is currently a Solution Analysis Manager at
              WooliesX, where she works to transform the digital landscape of
              the company alongside a team of like-minded individuals. After
              graduating with a degree in electrical engineering, Nina has
              worked in numerous roles within the engineering and technology
              industries, from which her love and fascination for the ever
              changing fields has stemmed.
            </p>
            <p className={styles.postContent}>
              Join us this week as we take a deep dive into Nina’s passion for
              the dynamic field of technology, and her highly diverse career
              journey.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              I chose to join engineering because of the fun and excitement!
              Engineering is the field that solves some of the most difficult
              and impactful problems in the world, and can create real change
              for good with new inventions, effective solutions, and cool
              gadgets!
            </p>
            <p className={styles.postContent}>
              Just think about your everyday life: how do television screens
              make an image? How can you send your video to someone in another
              country? How do airbags know when to inflate? How do electric cars
              work? For me, engineers are like the magicians of our society.
            </p>
            <p className={styles.postContent}>
              Society looks to engineers to solve problems that they may not
              even know they have! We can make life easier for millions of
              people. Being an engineer can unleash your creativity and you will
              find yourself capable of solving any problem (not just technical
              ones) because your brain is now trained and ready to analyse
              anything!
            </p>
            <br />

            <p className={styles.question}>
              Please provide a short summary of your career journey so far:
            </p>

            <p className={styles.postContent}>
              After graduating as an electrical engineer, I joined a
              telecommunication company as an intern and worked there part-time
              for 3 months. This gave me the opportunity to gain insight into
              other fields of engineering, like computer science and
              programming. Since, I have worked in a variety of roles from
              Electric Circuit Designer to Field Maintenance Engineer, Wireless
              Network Engineer, Radio Frequency Support Engineer and
              Infrastructure Engineer, to Solutions Architect and Solution
              Analysis Manager.{" "}
            </p>
            <br />

            <p className={styles.question}>
              Where do you see yourself in the future (careers wise)?
            </p>

            <p className={styles.postContent}>
              What's really exciting about a career in engineering is that there
              are so many opportunities to grow and develop, and with technology
              moving so quickly you never know where you might be able to go
              next. For me I'm passionate about people and teams, so I hope to
              pursue opportunities to manage and develop technical teams as well
              as champion diversity in our industry.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What do you love about your career now?
            </p>

            <p className={styles.postContent}>
              I love the technology industry because it is constantly changing.
              It boosts people that are creative, autonomous, and driven. It has
              been such a great opportunity and a great journey to re-discover
              my career interests, skills, and weaknesses, too. I love my career
              because I learned how to talk more technically, about business
              visualizing, and actionable roadmaps.
            </p>
            <p className={styles.postContent}>
              Also, no matter how up-to-date you think you are, you really are
              not, because many things are going on at the same time you are
              finishing a project. So there is always something new to learn!
              Most recently, at WooliesX I’ve enjoyed learning how to grow and
              support an organisation through digital transformation, which is
              always challenging, and how to help drive change. I see that the
              greatest and most highly motivated teams are those that are
              multi-disciplinary, diverse, and respectful to one another. And
              that when people are united by purpose and values you will surely
              achieve awesome results, create a huge impact, and develop their
              career.
            </p>
            <p className={styles.postContent}>
              As a professional woman I’m privileged and grateful to work in a
              career where you can count on amazing female leaders and peers who
              thrive every day, and that work hard to make change happen for an
              industry that is traditionally been male dominated.
            </p>
            <p className={styles.postContent}>
              So, if you are wondering what to do with your career development,
              come to technology!
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their empowerment in the STEM industry?{" "}
            </p>

            <p className={styles.postContent}>
              Currently women are underrepresented in STEM, so we need to
              encourage involvement and raise awareness of the opportunities.
              This is important because in a diverse environment, both men and
              women can bring unique perspectives, creativity and innovative
              ideas, and diverse thinking always results in even better
              solutions.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the challenging aspects of your career?
            </p>

            <p className={styles.postContent}>
              At times it was challenging to ascertain my status in a domain
              that is traditionally dominated by certain demographics. It was
              helpful to have the support of some great leaders who understood
              the importance of diversity and helped me navigate challenges
              effectively to achieve my career goals. Another challenge, of
              course, is staying up to date with fast-moving technology so I can
              continue to deliver impactful, relevant solutions for customers.{" "}
            </p>
            <br />

            <p className={styles.question}>
              How have others inspired you at different periods throughout your
              career?
            </p>

            <p className={styles.postContent}>
              My managers, my peers and so many other people who have shared
              their experiences and stories. Inspiration can really come from
              anywhere!{" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>
            
            <p className={styles.postContent}>
              <p className={styles.question}>
                Hard Skills 
              </p>
              <li className={styles.list}>
                Technical skills 
              </li>
              <li className={styles.list}>
                Computer skills
              </li>
              <li className={styles.list}>
                Microsoft Office skills 
              </li>
              <li className={styles.list}>
                Analytical skills 
              </li>
              <li className={styles.list}>
                Marketing skills  
              </li>
              <li className={styles.list}>
                Presentation skills  
              </li>
              <br />
              <p className={styles.question}>
                Soft Skills
              </p>
              <li className={styles.list}>
                Teamwork  
              </li>
              <li className={styles.list}>
                Communication skills 
              </li>
              <li className={styles.list}>
                Problem-Solving skills  
              </li>
              <li className={styles.list}>
                Work Ethic
              </li>
              <li className={styles.list}>
                Flexibility/Adaptability
              </li>

            </p>

            <p className={styles.postContent}>
              Also nowadays "Apart from the usual teamwork and communication
              skills, companies try to build tight social networks, and look for
              people who help build the social fabric. Therefore, during the
              time of increasing remote working, social skills, the ability to
              work independently, and a positive attitude are valued!".{" "}
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              Be curious, creative, and ask questions.{" "}
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto? </p>

            <p className={styles.postContent}>
              “The best protection any woman can have… is courage.” {" "}
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "therese-nguyen": [
              "/portraits/blog-authors/therese-nguyen.png",
              styles.anonProfile,
              "Therese Nguyen",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost69;
