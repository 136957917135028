import React, { useEffect, useState } from "react";
import PageHeader from "../components/header";
import ContactForm from "./contactForm";
import styles from "./contactUs.module.css";
import LoadingScreen from "../components/LoadingScreen";

const ContactUs = () => {
  const [sourceLoading, setSourceLoading] = useState(true);
  const [headerLoading, setHeaderLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // control when to stop loading
  useEffect(() => {
    setTimeout(() => {
      setSourceLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {sourceLoading && headerLoading ? (
        <LoadingScreen />
      ) : (
        <div>
          {/* Cover Photo */}
          <PageHeader
            imgUrl="/headers/2021-exec-header.jpg"
            title="Contact Us"
            imageLoading={setHeaderLoading}
          />
          <div className={styles.form}>
            <h2 className={styles.inquiry}>Send Through a General Inquiry</h2>
            <div className={styles.formRight}>
              <img
                src={
                  window.matchMedia &&
                  window.matchMedia("(prefers-color-scheme: dark)").matches
                    ? `${
                      process.env.PUBLIC_URL + "/willow_contact_dark_mode.png"
                    }`
                    : `${process.env.PUBLIC_URL + "/willow_contact.png"}`
                }
                width="90%"
                height="400px"
                className={styles.centre}
                alt="contact us"
              />
            </div>
            <div className={styles.formLeft}>
              <ContactForm />
            </div>
          </div>

          <h2 className={styles.contactTeam}>CONTACT OUR TEAM</h2>
          <div className={styles.newEmails}>
            <div className={styles.col2}>
              <div className={styles.profile2}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/pres-icon.png"}
                  alt="pres-icon"
                />
                <h3>Jane Wang</h3>
                <a href="mailto:jane@unswwit.com">jane@unswwit.com</a>
                <a href="mailto:president@unswwit.com">president@unswwit.com</a>
              </div>
              <div className={styles.profile2}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/pres-icon.png"}
                  alt="pres-icon"
                />
                <h3>Nishimi Kapoor</h3>
                <a href="mailto:nishmi@unswwit.com">nishmi@unswwit.com</a>
                <a href="mailto:president@unswwit.com">president@unswwit.com</a>
              </div>
              <div className={styles.profile2}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/sec-icon.png"}
                  alt="sec-icon"
                />
                <h3>Emily Dang</h3>
                <a href="mailto:emilydang@unswwit.com	">
                  emilydang@unswwit.com	
                </a>
                <a href="mailto:secretary@unswwit.com">secretary@unswwit.com</a>
              </div>
              <div className={styles.profile2}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/tr-icon.png"}
                  alt="tr-icon"
                />
                <h3>Tiana Douroudis</h3>
                <a href="mailto:tiana@unswwit.com	">tiana@unswwit.com	</a>
                <a href="mailto:treasurer@unswwit.com">treasurer@unswwit.com</a>
              </div>
            </div>

            <hr
              className={[styles.lineBreak, styles.solid].join(" ")}
              width="90%"
            />
            <div className={styles.col3}>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/careers-icon.png"}
                  alt="careers-icon"
                />
                <h3>Brianna Kim</h3>
                <a href="mailto:brianna@unswwit.com	">brianna@unswwit.com</a>
                <a href="mailto:careers@unswwit.com">careers@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/careers-icon.png"}
                  alt="careers-icon"
                />
                <h3>Vivian Wang</h3>
                <a href="mailto:vivianwang@unswwit.com">vivianwang@unswwit.com	</a>
                <a href="mailto:careers@unswwit.com">careers@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/comps-icon.png"}
                  alt="comps-icon"
                />
                <h3>Vivian Wong</h3>
                <a href="mailto:vivianwong@unswwit.com">vivianwong@unswwit.com</a>
                <a href="mailto:competitions@unswwit.com">competitions@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/ex-icon.png"}
                  alt="ex-icon"
                />
                <h3>Gayathri Balaji</h3>
                <a href="mailto:gayathri@unswwit.com">gayathri@unswwit.com</a>
                <a href="mailto:externals@unswwit.com">externals@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/ex-icon.png"}
                  alt="ex-icon"
                />
                <h3>Tanisha Dasmohaptra</h3>
                <a href="mailto:tanisha@unswwit.com">tanisha@unswwit.com</a>
                <a href="mailto:externals@unswwit.com">externals@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/hr-icon.png"}
                  alt="hr-icon"
                />
                <h3>Emily Chang</h3>
                <a href="mailto:emilychang@unswwit.com">emilychang@unswwit.com</a>
                <a href="mailto:hr@unswwit.com">hr@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/it.png"}
                  alt="it-icon"
                />
                <h3>Joanna He</h3>
                <a href="mailto:joanna@unswwit.com">joanna@unswwit.com</a>
                <a href="mailto:it@unswwit.com">it@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/mark-icon.png"}
                  alt="mark-icon"
                />
                <h3>Abigail Joesan Johan</h3>
                <a href="mailto:abigail@unswwit.com">abigail@unswwit.com</a>
                <a href="mailto:marketing@unswwit.com">marketing@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/mark-icon.png"}
                  alt="mark-icon"
                />
                <h3>Gabriella Hartanto</h3>
                <a href="mailto:gabriella@unswwit.com">gabriella@unswwit.com</a>
                <a href="mailto:marketing@unswwit.com">marketing@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/media-icon.png"}
                  alt="media-icon"
                />
                <h3>Natalie Leroy</h3>
                <a href="mailto:natalie@unswwit.com">natalie@unswwit.com</a>
                <a href="mailto:media@unswwit.com">media@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/pubs-icon.png"}
                  alt="pubs-icon"
                />
                <h3>Chelsea Chaffey</h3>
                <a href="mailto:chelsea@unswwit.com">chelsea@unswwit.com</a>
                <a href="mailto:publications@unswwit.com">publications@unswwit.com</a>
              </div>
              <div className={styles.profile3}>
                <img
                  src={process.env.PUBLIC_URL + "/icons/pubs-icon.png"}
                  alt="pubs-icon"
                />
                <h3>Yuhan Zhou</h3>
                <a href="mailto:yuhan@unswwit.com">yuhan@unswwit.com</a>
                <a href="mailto:publications@unswwit.com">publications@unswwit.com</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactUs;
