import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost80 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #80" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Kristine Borisova, Product Manager at WiseTech Global
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>February 16, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              With a Black Belt in Thinking and a passion for working with
              people and technology, Kristine has transformed her skills in
              problem solving and project planning into a career. Graduating
              with a Masters in Information Technology at UTS, Kristine first
              worked in database administration and business analysis before
              finding her passion in product management at WiseTech Global. This
              week, as we explore her journey and advice, we reflect upon the
              importance of teamwork, collaboration and leadership in the tech
              industry.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              Actually my teachers at high school encouraged me to join the STEM
              specialization in years 10-12, as well as to participate in Maths
              & Science camps and competitions. Then later, when browsing the
              university programs, I naturally gravitated towards technology
              subjects. More often than not, when we are young, we have no idea
              what we want to do, or even what's out there. So I really think
              that the trick is to try different things and see what sparks your
              interest the most.
            </p>
            <br />

            <p className={styles.question}>
              Tell me about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              While still studying I had a number of technology related jobs and
              responsibilities. But my first role at a tech company came soon
              after I graduated. I started in technical support and worked my
              way up to the support team lead, then moved to a business analyst
              role and then later to product management. Throughout the years, I
              would often be the one to put my hand up for the various projects
              and tasks, even if it's not my area of responsibility or within my
              comfort zone. I know that various challenges help me learn and
              grow.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              When I was at uni, I used to attend ALL the free events I could
              find, such as guest lectures, talks and presentations. There was
              always free wine and snacks (!) and great presenters from
              different companies and organizations. There were really inspiring
              stories, case studies and insights that really fuelled my interest
              in technology. These days I like listening to podcasts and
              audiobooks. So it's not a particular person, it's more the various
              stories and insights that inspire me.
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              The story that STEM is just not for girls is a great
              misunderstanding. Women are AMAZING at it. The contribution that
              women bring to technology, science and engineering is invaluable.
              STEM industries create products that are used by women. So it is a
              great loss if not enough women are involved in dreaming up,
              designing and developing these products. And so we must help the
              girls not be intimidated by it.
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              There is a popular opinion right now that in the future, the soft
              skills will be in higher demand than the hard skills. The truth
              is, working in STEM is working with people - a great variety of
              people - and in teams to bring ideas to life. Many young people I
              talk to are putting a lot of pressure on themselves to study
              double degrees, take up internships, part time work and additional
              certifications. This is all great, but I really feel that young
              people should make time and effort to really experience our great
              big world, learn about the people and about working with people.
              Things like travelling to different countries and having a real
              interest in the different cultures, participating in sports and
              various communities, working in places such as hospitality, retail
              and tourism. These experiences not only enrich your life, but also
              equip you with valuable skills. Remember that graduates who
              exhibit a higher emotional intelligence and empathy have a higher
              chance to really succeed in the workplace.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              Definitely the people I work with. When we think of the great
              companies, we think of their founders and CEOs. They are great
              visionaries for sure. But it's the people on the ground who are
              the real heroes and who make it all happen. I am motivated to get
              up in the morning every day because I don't want to let my team
              down above all. The great energy, the buzz and the drive of the
              people I work with is my greatest motivation.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              I think I am one of the fortunate ones who managed to recognize
              what I really like and stay true to that. I always set high goals
              and standards for myself. But at the same time, I made sure I had
              as much fun as possible along the way, by travelling the world and
              having a variety of experiences and memories. If I could give one
              piece of advice for my younger self, it would be to make more
              networks and connections with the people I studied with.
              Throughout the years, these are the people you could bounce ideas
              with and help each other in the respective career journeys. The
              few uni friends I still keep in touch with, have a very special
              role and place in my heart, so I wish there were more.
            </p>

            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>
              When I feel intimidated by some task or job, or when I feel out of
              my comfort zone, I say this to myself: "If not you, then who?"
              It's something my parents and grandparents used to say when I was
              a kid. The trick is not trying to overthink it or to try to answer
              it (because let's be honest, if prompted, we can always think of
              many other much more capable people). The trick is to use it in
              order to boost your confidence and really believe in yourself. If
              not me, then who?
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "chelsea-chaffey": [
              "/portraits/blog-authors/chelsea-chaffey.png",
              styles.authorProfile,
              "Chelsea Chaffey",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost80;
