import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost75 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #75" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>A Guide to Networking</h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>October 29, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Undoubtedly, networking can be a nerve-wracking experience. What
              do you ask? How do you make an impression in such a short amount
              of time? Are you asking the right questions? With WIT’s Annual
              Networking Night coming up, we look at top tips to set you up for
              success when networking.
            </p>
            <br />

            <p className={styles.question}>1. Go in ready to learn</p>

            <p className={styles.postContent}>
              Networking nights are the perfect opportunity to learn and share
              experiences: whether that be learning about someone’s career
              journey, or seeing how someone else’s experience can help you
              through the difficulties you face as a student. Networking is not
              a competition, you do not need to be the most outstanding person
              in the room, but having a keen interest to learn and find out
              about people’s experiences can certainly help you to succeed.
            </p>
            <br />

            <p className={styles.question}>2. Know what you want to ask</p>

            <p className={styles.postContent}>
              At networking nights you often know the people or companies who
              will be there. This gives you the advantage of being able to do
              prior research; maybe you have a question about a program the
              company is running or a specific question related to someone’s
              career journey. In addition, preparing questions you want to ask
              or things you want to learn beforehand can relieve any potential
              anxiety out of what to say and when to say it.
            </p>
            <br />

            <p className={styles.question}>
              3. What are your shared interests?
            </p>

            <p className={styles.postContent}>
              What do you have in common with the person you are speaking with?
              This might be about your degree, passions, career paths, current
              roles, previous experiences, or even just the fact that you’re
              both at the event. Not only does finding a shared interest help
              you build a sincere relationship, it also creates a degree of
              friendliness that makes the conversation a lot more enjoyable.
            </p>
            <br />

            <p className={styles.question}>4. Be an active listener</p>

            <p className={styles.postContent}>
              It is often very easy to get carried away with being concerned
              about how to deliver yourself in front of people in the networking
              event. But don't undermine the importance of being an active
              listener as well. Being able to carefully listen to what someone
              else is saying is important. It will allow you to find answers to
              your questions, hear about the breadth of their experience, see
              the bigger picture, and spark new questions!
            </p>

            <br />

            <p className={styles.question}>
              5. It’s an opportunity to build your skillset
            </p>

            <p className={styles.postContent}>
              Behind the anxiety of networking that exists for many, pushing
              yourself out of your comfort zone to develop important soft skills
              is a massive motivator to keep trying and getting involved. In
              networking events you are able to develop your confidence,
              communication, and also conversation skills that will set you up
              not only for your next networking event, but really any social
              interaction you have in the future.
            </p>
            <br />

            <p className={styles.question}>6. Follow up</p>

            <p className={styles.postContent}>
              Make sure you stay in contact with people you have enjoyed talking
              to! Add them on LinkedIn or exchange your emails, and make sure
              you keep in touch. Given the limited time of networking events,
              use your regular check-ins to ask outstanding questions you have,
              tell them what you have been up to, and find out how they are too!
              Building and keeping genuine relationships can keep you closely
              connected with industry, and even become your pathway into your
              dream company.
            </p>
            <p className={styles.postContent}>
              Networking can seem intimidating, but with simple tips and
              enthusiasm to meet and learn from new people, you are sure to have
              success!
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "georgie-mansfield": [
              "/portraits/blog-authors/georgie2021.jpg",
              styles.authorProfile,
              "Georgie Mansfield",
              "Education Executive",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost75;
