import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";
import GoogleAnalytics from "react-ga";

class blogPost72 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #72" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>Can We Live Forever?</h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>October 8, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Ageing is an inevitable, universal trait that does not
              discriminate. As time goes on, our body slowly deteriorates due to
              molecular and cellular damage. The gradual decline both physically
              and mentally means a lower immunity leading to eventual death. The
              question plaguing us for generations has been can we stop this? Or
              even better, reverse the effects?
            </p>

            <p className={styles.postContent}>
              Achieving anti-ageing fundamentally boils down to evaluating the
              biology behind what causes death. The technology available now can
              be seen on a smaller scale, for example, there are natural options
              to prevent ageing. These include specific diets which consist
              heavily of vegetables and fruits, to bathing in a lake full of
              minerals such as the Blue Lagoon, in Iceland. There are artificial
              alternatives as well, such as anti-ageing creams for wrinkles and
              cryogenics. However, these methods cannot compare to the companies
              funded by billionaires.
            </p>

            <p className={styles.postContent}>
              There is a company called Calico, owned by Google, where they
              explore the biology behind aging and longevity. They hope to
              understand what makes a human have a healthier life, and thus even
              a longer one. To do this, they look at risk factors for disease
              and collaborate with partners such as MIT and Harvard to explore
              drug discovery programs. 
              <br/>
              Aspects Calico look at include:
              <li className={styles.list}>Business Analyst </li>
              <li className={styles.list}>Automation </li>
              <li className={styles.list}>Biochemistry</li>
              <li className={styles.list}>Flow Cytometry </li>
              <li className={styles.list}>Genomics</li>
              <li className={styles.list}>Mass Spectrometry</li>
              <li className={styles.list}>Microscopy</li>
              <li className={styles.list}>Physiology</li>
              <li className={styles.list}>Pharmacology</li>
              <li className={styles.list}>Preclinical Imaging</li>
            </p>

            <p className={styles.postContent}>
              From this list, it is evident that Calico explores a large range
              of components that relate to ageing, and by doing research across
              different fields, allows them to scope the best idea of why a
              human age and how to prevent that. Some examples of their research
              include investigating how yeast ages, however, none have come
              close to slowing down how humans age. They often make publications
              on their website relating to this research, with{" "}
              <GoogleAnalytics.OutboundLink
                eventLabel="blog-post-72-calico"
                to="https://www.calicolabs.com/publications"
                target="_blank"
                title="Calico publications"
              >
                the most recent article
              </GoogleAnalytics.OutboundLink>{" "}
              being made this year in September.
            </p>

            <p className={styles.postContent}>
              More recently, a large group of scientists, joined by investors,
              met through a teleconference to discuss biotechnology and its
              connection to make reverse ageing possible. From this, a new
              anti-ageing company is now established called Altos Labs, owned by
              Milner. The company explores the process by attempting to
              rejuvenate cells, hence prolonging life. There are several
              problems with reprogramming cells, as turning a skin cell into a
              stem cell changes its identity- making the technology too
              dangerous to try on humans at the moment. Similarly to Calico,
              both owners of the companies are billionaires. Investors include
              other billionaires too, like the former CEO of Amazon, Jeff Bezos.
              This raises concerns, as it only appears that the company will be
              helping those wealthy enough to afford the technology. There are
              also ethical concerns with altering one’s ‘biological clock’ and
              to what extent should humans stop experimenting with nature. It
              also highlights the fact that despite having all the money in the
              world, we all end up the same way.{" "}
            </p>

            <p className={styles.postContent}>
              When navigating the possibility of reverse ageing, one is faced
              with not only biological issues but also ethical concerns as well.
              The technology is proven to have potential and slow down the
              ageing process. However, the question becomes not so much
              concerned with living forever but rather to what extent can we
              delay the inevitable,who will have access to it, and should we be
              trying to reverse this natural process?{" "}
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "natalie-leroy": [
              "/portraits/blog-authors/natalie-leroy.png",
              styles.anonProfile,
              "Natalie Leroy",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost72;
