import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost73 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #73" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Celeste Lowe, Cyber Security Leader at Nine
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>October 20, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Being in the Cyber Security industry for over 20 years, Celeste 
              Lowe has proven her ongoing passion for industry development 
              through her involvement in multiple projects, such as building 
              Deloitte’s Cyber Intelligence Center, and her outstanding traits 
              as a lifelong learner. She believes that her managerial skills, 
              strategic planning and operational delivery experience have grown 
              throughout the years, by applying herself to every opportunity 
              offered in the IT industry. Celeste’s striving for equality and 
              diversity, and success in STEM have inspired other women to follow
              their dreams and pursue careers in STEM. This week, we will talk 
              to Celeste about her career, as well as valuable advice and 
              insights she has about the tech industry.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              Cyber Security was a relatively unknown field 25 years ago and I 
              liked being a part of something that wasn't well defined, but that 
              I could grow with and learn. And I'm still learning!{" "}
            </p>
            <br />

            <p className={styles.question}>
              Please provide a short summary of your career journey so far:
            </p>

            <p className={styles.postContent}>
              I took a couple of career paths before settling into IT. I was a 
              baker for 4 years, then did a stint in the RNZAF (Royal New Zealand 
              Air Force) specialising in telecommunications. Nothing felt quite 
              right until I found my way into IT starting in tech support, account 
              management and mainframe admin which morphed into specialising in 
              Cyber Security.  {" "}
            </p>
            <p className={styles.postContent}>
            I did a long stint of 16.5 years with Computer Science Corp (now DXC) 
            and built their global security incident response centre (SIRCC) and 
            managed the internal security and risk team here in Australia.  
            After building the Cyber Intelligence Centre here in Sydney for Deloitte, 
            I found my dream job with Qantas as head of Cyber for the Airlines for 
            4 years. In the middle of the pandemic, a new role as Group Director 
            of IT security at Nine was offered, so I couldn't pass up it. Loving 
            it! {" "}
            </p>
            <br />

            <p className={styles.question}>
              Where do you see yourself in the future (career wise)?
            </p>

            <p className={styles.postContent}>
              I didn't know what I wanted to do when I left school. I still 
              take every day as it comes. Sometimes it doesn't pay to plan 
              too far ahead and let opportunities and life guide you.
            </p>  
            <p className={styles.postContent}>
              I live by one of my key life principles. If you've made a choice 
              and find you don't like it... make another one and change it. 
              I juggle a family and career, so choices for me have to come 
              down to a balanced lifestyle. {" "}
            </p>
            <br />

            <p className={styles.question}>
              What do you love about your career now?
            </p>

            <p className={styles.postContent}>
              Variety and people and making a change. It is always good to 
              expect the unexpected. {" "}
            </p>
            <p className={styles.postContent}>
              A day can often be derailed by an event, such as a hacking attempt, 
              data breach, malware outbreak or application outage. Figuring out 
              what is going on and helping to manage the impact and response.  
              Throw in Audits, metrics on how effective the security of systems 
              are operating along with legal and contractual reviews makes for 
              a broad and very diverse but very rewarding working day. {" "}
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase 
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              Women bring perspectives and often a human care and people element 
              to every part of the business. Thinking outside the box and how to 
              focus on influencing not just the doing are really important 
              qualities, which are not just technical skills that I find women 
              bring to all STEM careers. {" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the challenging aspects of your career?
            </p>

            <p className={styles.postContent}>
              ME. {" "}
            </p>
            <p className={styles.postContent}>
              I can often be my own challenge! I've spent many years doubting 
              myself and finding my voice. I am now comfortable in speaking up
              my opinion or asking a question when I do not know something. 
              I had to get out of my own way and remove myself from a challenge. 
              Stop second-guessing myself.{" "}
            </p>
            <p className={styles.postContent}>
              Public speaking for me is a challenge. The more I do it, the less 
              of a challenge it becomes. {" "}
            </p>
            <br />

            <p className={styles.question}>
              How have others inspired you at different periods throughout your 
              career?
            </p>

            <p className={styles.postContent}>
              I've had a couple of very strong women that I've been inspired 
              by and loved working with, and in many ways, all have been mentors 
              guiding me in my career and how I manage my teams and programs of 
              work. {" "}
            </p>
            <p className={styles.postContent}>
              When I started out, Ruth Harper in NZ for her technical skill set 
              and passion for women's rights was guiding/inspiring to me. {" "}
            </p>
            <p className={styles.postContent}>
              Judy Wheeler for her strength, decision-making skills, and 
              challenging the gender norms. {" "}
            </p>
            <p className={styles.postContent}>
              Kim Valois, who is now CISO at Flinders Uni, for her leadership 
              style, collaboration and people skills. {" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students 
              who are interested in pursuing a similar career path (for 
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              Learn how to communicate and collaborate well. Tech skills are 
              great, but people skills and learning how to work with others 
              are essential. {" "}
            </p>
            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger self 
              back in university?
            </p>

            <p className={styles.postContent}>
              Don't put off until tomorrow what you can do today. Doing papers 
              at the last min causes so much stress! {" "}
            </p>
            <br />

            <p className={styles.question}>
              Favourite quote or motto?
            </p>

            <p className={styles.postContent}>
              Don't eat the elephant. Have the goal and the vision but ensure 
              that on your journey you need to get there one step at a time. 
              {" "}
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "sue-vo": [
              "/portraits/blog-authors/sue-vo.png",
              styles.anonProfile,
              "Sue Vo",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost73;
