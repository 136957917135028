import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost68 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #68" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>How to Learn to Code</h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>July 29, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.question}>
              How Do I Launch My Coding Journey?
            </p>

            <p className={styles.postContent}>
              Regardless of which point of stage in life you are at, coding is
              there for everybody to learn. It is perfectly fine if you are
              someone who has no prior background or coding experience.
              Upskilling is so essential these days and with the plethora of
              online courses widely available on many websites, you can never go
              wrong in missing an opportunity to learn, As a beginner or even an
              experienced programmer, you have plenty of online resources and
              assisting support to explore in honing your coding skills such as
              Coursera, Udemy, Edx, Youtube Tutorials, Online Code Bootcamps,
              Stack Overflow forums, etc. The Internet has brought so much
              remote learning convenience to us and therefore, instead of always
              questioning ourselves “Can a person like me do it?”, we should
              channel our energy and time investments towards sharpening our
              coding foundations and the coolest part is when our passion for
              coding is developed, we can build our own projects like websites,
              mobile apps or databases to add into our portfolios.
            </p>

            <p className={styles.postContent}>
              For beginners, it is strongly reckoned to start off with basic
              programming courses which cover introductory levels of content for
              learners to familiarize themselves with the programming context.
              The popular programming languages out there are currently Python,
              C/C++, Javascript, R, Matlab and HTML which learners can look up
              to acquire during their coding pathways. Python is highly
              recommended for beginners to get started on first before branching
              out to the rest as it is the easiest assembly-level language that
              can be logically understood in English terms. As you plot your
              coding journey, it is also good to search up the top programming
              languages that are the most relevant to today’s workforce and from
              there, pick up 2-4 languages to practice consistently. Similar to
              math and any other languages, coding is a skill requiring constant
              practice and familiarity touch in order to excel in their
              masteries. Thus, if you wish to apply coding knowledge to your
              professional careers, you will need to have patience in learning
              the languages productively.
            </p>

            <p className={styles.postContent}>
              The journey to programming mastery can be long or short, it is
              entirely dependent on you as a learner. To reiterate, what matters
              the most is how you will apply your coding skills in real-life
              situations afterwards!
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "erika-shi": [
              "/portraits/blog-authors/erika-shi.png",
              styles.anonProfile,
              "Erika Shi",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost68;
