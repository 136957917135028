import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost82 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #82" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Caitlin Hargraves, Senior Graduate Recruiter at FDM
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>February 23, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Committed to growing and challenging herself, Caitlin sought to
              combine her interest in technology and passion for people to
              pursue a career in the graduate talent acquisition industry. After
              graduating from the University of Canberra with a degree in Human
              Resource Management, Caitlin joined FDM Group where she is
              currently working as a Senior Graduate Recruiter. She has
              extensive experience mentoring, speaking, delivering workshops,
              presentations and other initiatives to seek future talents in
              technology. This week, we discuss why she loves her role, the
              successes and challenges in her career journey, and essential
              insights in how to land a graduate position in technology.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              Growing up, I was surrounded by technology through video games,
              pulling old computers apart for fun, and just in general day to
              day life. As I grew older I was able to see that technology was
              more than just for fun - it was something that was changing the
              way we were living. When I graduated, I found myself a role as a
              graduate recruiter, hiring graduates for the IT space, which has
              allowed me to see what impact technology will continue to have in
              the future.
            </p>
            <br />

            <p className={styles.question}>
              Tell me about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              After completing my studies at the University of Canberra with a
              Bachelor of Human Resource Management, I decided to explore the
              graduate talent acquisition space because I enjoyed working with
              young people and thought kickstarting careers would be a really
              fulfilling job. I’ve recently completed two years in my role at
              FDM Group, become a senior recruiter in the team, spoken with
              thousands of students and graduates, and launched the careers of
              80 graduates through our program. I loved getting out on campus,
              delivering workshops and presentations, and attending careers
              fairs to meet lots of new people to spot future tech talent.
            </p>
            <p className={styles.postContent}>
              I’ve experienced a steep learning curve working remotely through
              the COVID 19 pandemic, which has been challenging. Building
              relationships online is really important to keep people feeling
              connected and it’s great to know that the technology sector is
              making that possible. We are seeing just how important technology
              is through this pandemic. I love working with candidates and
              supporting their journey, and I get a real buzz when they keep in
              touch and I find out about how their story evolves as their work
              experience grows. I’m really proud of how many lives I’ve impacted
              by supporting their recruitment process and launching their
              careers in tech.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              Sophie Zdenkowski- FDM Group's University Partnerships Manager.
              Sophie has always shown so much hard work and dedication in the
              role and has demonstrated that things can be completed with focus,
              passion and a positive attitude. Sophie has always encouraged me
              to work harder, and smarter whilst also helping me think about
              what is important to me in my career. The mentorship I have been
              provided with pushes me every day.
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              Diversity in the STEM space has always proven to be a challenge,
              but I believe it is important to find more female talent in the
              space not only to challenge bias in the industry, but to also
              provide the much needed diversity that brings a wider range of
              skills and approaches to projects.
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              Through my experience as a graduate and a technical recruiter, I
              have found that a lot of companies are utilising their digital
              capabilities despite whether or not you are starting in a
              technical role. There are several skills that I believe would be
              beneficial for most graduates finding their first role.
            </p>
            <p className={styles.postContent}>
              For hard skills - database management, statistical analysis,
              coding skills, project management. Upskilling in technical
              capabilities can help graduates work more efficiently, boost their
              confidence in their role, and can also make the graduate more
              valuable to potential employers. There are many great resources
              online that allow graduates to upskill in these capabilities
              without having to undertake extra studies.
            </p>
            <p className={styles.postContent}>
              For soft skills - effective communication, team work, problem solving,
              critical thinking, willingness to learn and adaptability. You will
              learn a lot of critical skills in your role, however as a graduate
              it is important to have these skills to allow you to adapt to your
              new role, take on steep learning curves and grow as quickly as you
              can within your role.
            </p>
            <p className={styles.postContent}>
              It is always beneficial for you to research the roles and
              industries that you are interested in and try to get as much
              experience and involvement as possible through university,
              personal projects and work.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              Coming from a small country town in NSW, I never thought I would
              have found myself achieving what I have today - a successful job
              in the corporate world, in a large exciting city where I am able
              to help graduates find what I have found. I love helping to create
              opportunities, and I feel the gratitude from all of the graduates
              I have helped to date. Hearing that I have helped someone start
              their dream job brings me the most amount of happiness.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              Find what works for you, be easy on yourself, do it for you.
            </p>
            <p className={styles.postContent}>
              I definitely used to be one of my worst critics, and used to
              compare my success to that of others around me but as soon as I
              focused on my own success - I became a happier, more determined
              and better version of myself.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>
              "I will become what I deserve"- Ben Howard
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "yuhan-zhou": [
              "/portraits/blog-authors/yuhan.jpg",
              styles.authorProfile,
              "Yuhan Zhou",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost82;
