import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";
import GoogleAnalytics from "react-ga";

class blogPost70 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #70" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Careers at{" "}
              <p className={styles.blogTitle} id={styles.titleWooliesX}>
                WooliesX
              </p>
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>September 23, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.question} id={styles.wooliesX}>
              About Us
            </p>

            <p className={styles.postContent}>
              At WooliesX, we’re here to create better everyday experiences for
              our customers. In the big picture, and the fine detail. In moments
              big and small.
            </p>
            <p className={styles.postContent}>
              WooliesX is part of the Woolworths Group. We’re an innovation
              business that brings together the brightest minds in e-commerce,
              technology, media and data to transform the way people live and
              shop.
            </p>
            <p className={styles.postContent}>
              We’ve worked together with our partners to create all kinds of
              initiatives you know and love – like Everyday Rewards, one of
              Australia’s most popular loyalty programs. Alongside Woolworths
              Supermarkets, we’ve helped make online shopping simpler and more
              intuitive, and were awarded #1 in customer satisfaction for online
              groceries by Canstar for our combined effort.
            </p>
            <p className={styles.postContent}>
              Diversity is our strength, through the potential we unlock
              together. We’re resourceful and open-minded, welcoming and willing
              to experiment. We encourage fresh ideas and help each other be
              better by providing ongoing career opportunities to grow and
              evolve.{" "}
            </p>
            <p className={styles.postContent}>
              It’s how we bring the future to life for our customers and our
              people, in everyday moments that matter.{" "}
            </p>
            <br />

            <p className={styles.question} id={styles.wooliesX}>
              Most Valued Skills
            </p>

            <p className={styles.postContent}>
              There is no cookie-cutter for WooliesX team members, but we are
              passionate about the following skills:
              <li className={styles.list}>
                Creative problem-solving {"&"} technical fundamentals{" "}
              </li>
              <li className={styles.list}>
                Agile mindset with great communication{" "}
              </li>
              <li className={styles.list}>Embody the customer experience </li>
              <li className={styles.list}>
                Passion for growth and innovation{" "}
              </li>
              <li className={styles.list}>
                Embrace team and collaboration with partners{" "}
              </li>
            </p>
            <br />

            <p className={styles.question} id={styles.wooliesX}>
              Expected Background for Technical Roles
            </p>

            <p className={styles.postContent}>
              We love to hear from candidates about their knowledge, experience
              or passion in the following:
              <li className={styles.list}>Common operating systems </li>
              <li className={styles.list}>Technical writing </li>
              <li className={styles.list}>Data analysis </li>
              <li className={styles.list}>Side {"&"} Personal Projects </li>
              <li className={styles.list}>
                Additional Technical Courses And Certificates{" "}
              </li>
              <li className={styles.list}>Internship Experiences </li>
            </p>
            <br />

            <p className={styles.question} id={styles.wooliesX}>
              Opportunities{" "}
            </p>

            <p className={styles.postContent}>
              Whatever your technical discipline, we want to hear from brilliant
              people who are ready to kick start their career in one of
              Australia’s most agile and technically advanced companies.
            </p>
            <p className={styles.postContent}>
              For recent graduates, we partner with{" "}
              <GoogleAnalytics.OutboundLink
                eventLabel="blog-post-70-hatch"
                to="https://www.hatch.team/discover-work/students/role/461"
                target="_blank"
              >
                Hatch
              </GoogleAnalytics.OutboundLink>{" "}
              to help place the right talent in the right role to work better
              together for our teams and customers.
            </p>
            <p className={styles.postContent}>
              <strong>Opportunities for Pre-Penultimate Students</strong>
            </p>
            <p className={styles.postContent}>
              WooliesX partners with our good friends at Code Like a Girl to
              continue to promote and encourage gender diversity within our tech
              teams. If you see your next move being an internship in AI/Machine
              Learning, Front-end/Back-end Development, Data
              Science/Visualization, DevOps, (or many more!), then{" "}
              <GoogleAnalytics.OutboundLink
                eventLabel="blog-post-70-code-like-a-girl"
                to="https://www.codelikeagirl.com/internships/"
                target="_blank"
              >
                {" "}
                register with Code Like a Girl
              </GoogleAnalytics.OutboundLink>{" "}
              to get started. We have 3-month, 6-month, full-time and part-time
              internships that come up all the time!
            </p>

            <br />

            <p className={styles.question} id={styles.wooliesX}>
              Application Process
            </p>

            <p className={styles.postContent}>
              <strong>Code like a Girl Internship Program </strong>
            </p>

            <p className={styles.postContent}>
              <ol>
                <li className={styles.list}>
                  Students interested are encouraged to register with{" "}
                  <GoogleAnalytics.OutboundLink
                    eventLabel="blog-post-70-code-like-a-girl"
                    to="https://www.codelikeagirl.com/internships/"
                    target="_blank"
                  >
                    {" "}
                    Code Like a Girl
                  </GoogleAnalytics.OutboundLink>
                  , our partners in ensuring a gender balanced tech-enabled
                  workforce{" "}
                </li>
                <li className={styles.list}>
                  When a role is available we will arrange interviews with
                  candidates from <strong>Code Like a Girl</strong>
                </li>
                <li className={styles.list}>
                  We don’t expect our interns to be Jedi Masters, and understand
                  that they may not have worked on all the tools and languages
                  we use, so our diverse panel will not bombard them with
                  detailed technical questions{" "}
                </li>
                <li className={styles.list}>
                  We will take lots of notes, so that we can give constructive
                  feedback to all our candidates{" "}
                </li>
                <li className={styles.list}>
                  When a candidate is accepted, we set them up for success
                  straight away, with all the tools they need to start, and a
                  ‘buddy’ to work with them along the way{" "}
                </li>
                <li className={styles.list}>
                  We don’t ditch our unsuccessful candidates, we’ll provide
                  positive and constructive feedback so that they know where
                  they need to strengthen in future{" "}
                </li>
              </ol>
            </p>
            <p className={styles.postContent}>
              <strong>Hatch Grad Program</strong>
            </p>

            <p className={styles.postContent}>
              <ol>
                <li className={styles.list}>
                  Our partners at Hatch will send us a list of suitable
                  candidates for new roles - our favourite part of the process -
                  we love meeting new and talented people!{" "}
                </li>
                <li className={styles.list}>
                  We’ll arrange interviews with the ones we think will fit best
                  into WooliesX - we’ll be looking for:
                  <ol type="a">
                    <li className={styles.list}>Personality fit </li>
                    <li className={styles.list}>
                      Technical know-how (we’ll expect a bit more from our
                      Graduates than our interns){" "}
                    </li>
                    <li className={styles.list}>
                      Passion - what’s been your side-hustle? What projects have
                      you been involved in and loved?{" "}
                    </li>
                  </ol>{" "}
                </li>
                <li className={styles.list}>
                  As with our interns, we take lots of notes during the
                  interview, so that we can help both our successful and
                  unsuccessful candidates improve{" "}
                </li>
                <li className={styles.list}>
                  For those successful candidates, we tailor a brilliant
                  onboarding, with full tech setup, conversations with the
                  people you’ll need to know in the business, and your own
                  personal ‘buddy’ to help you with the rest of it!{" "}
                </li>
              </ol>
            </p>
            <p className={styles.postContent} id={styles.center}>
              <GoogleAnalytics.OutboundLink
                eventLabel="blog-post-70-application-process-guide"
                to="https://drive.google.com/drive/folders/1lMR9lxHasRxPkQJG1DaseuMCZHSS2wPh"
                target="_blank"
              >
                {" "}
                Guides to the Application Processes Here!
              </GoogleAnalytics.OutboundLink>
            </p>

            <br />

            <p className={styles.question} id={styles.wooliesX}>
              How to Prepare for the Application Process
            </p>

            <p className={styles.postContent}>
              We want all our team members to bring their whole selves to work,
              and don’t want candidates to be robots, but these tips can help
              you get the best experience:
              <li className={styles.list}>
                Make your social media presence secure and your Linkedin profile
                professional{" "}
              </li>
              <li className={styles.list}>
                Research Woolies X - use LinkedIn, familiarise yourself with us{" "}
              </li>
              <li className={styles.list}>
                Read the Job Description and make sure it matches with your
                skills and interests{" "}
              </li>
              <li className={styles.list}>
                Ensure the CV and cover letter are match the role you are
                applying for{" "}
              </li>
              <li className={styles.list}>
                Research your interviewers profile and understand their
                background{" "}
              </li>
              <li className={styles.list}>
                Think about good questions to ask the interviewer{" "}
              </li>
            </p>
            <br />

            <p className={styles.question} id={styles.wooliesX}>
              Roles Available
            </p>

            <p className={styles.postContent}>
              We have Associate-level roles in the following fields for our
              technical candidates:
              <li className={styles.list}>Business Analyst </li>
              <li className={styles.list}>Data Analyst </li>
              <li className={styles.list}>QA Engineer (Test engineers) </li>
              <li className={styles.list}>Developers </li>
              <li className={styles.list}>User Experience (UX) Designer </li>
              <li className={styles.list}>Software Engineer: Front-End </li>
              <li className={styles.list}>Software Engineer: Back-End </li>
              <li className={styles.list}>Software Engineer: Full-Stack </li>
              <li className={styles.list}>Devops Engineer </li>
              <li className={styles.list}>Cyber Security Engineer/Analyst </li>
              <li className={styles.list}>Junior Architect </li>
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "therese-nguyen": [
              "/portraits/blog-authors/woolies_x.png",
              styles.anonProfile,
              "WooliesX",
              "Major Sponsor",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost70;
