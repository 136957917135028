import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost87 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #87" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>Why is Blockchain Unbreakable?</h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>March 17, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Imagine writing a diary that can’t be erased or overwritten.
              Imagine that for each entry you wrote, thousands of perfect copies
              are generated and sent around the world. And that if even a single
              letter was changed, the whole diary would collapse, rendering the
              copy mutilated. That is the concept of blockchain - an immutable
              public ledger shared across the globe; a digital record whose very
              structure renders it, more or less, immortal.
            </p>

            <p className={styles.postContent}>
              Blockchain is a modern cryptographic software system that records
              and preserves information across a peer-to-peer network. While it
              was first conceived for the cryptocurrency Bitcoin, blockchain can
              also be used to support international financial transactions
              without relying on a bank, and to lend money more efficiently via
              “smart contracts”. Non-financial applications of blockchain range
              from the secure sharing of medical records to the reduction of
              piracy in music and film. One application labelled “non-fungible
              tokens” (NFTs) guarantees the ownership rights and uniqueness of
              digital media. Many digital artworks today are auctioned for
              several million dollars through NFT technology. In the future,
              citizens might be able to vote on blockchain technology from their
              smartphone, ensuring that votes cannot be tampered with and
              reducing the cost of running an election.
            </p>

            <p className={styles.postContent}>
              You may be surprised to discover that despite its profound
              applications, blockchain is simple at heart. To understand
              blockchain, one must understand the hash function: a clever
              mathematical trick that turns some information into a garbled text
              string, which acts just like a fingerprint. This function is
              single-way: you can easily get a fingerprint from a human, but it
              is quite impossible to conjure a human from the fingerprint. In
              fact, a computer might have to use guess-and-check 2^256 times, a
              number infeasible for even our most advanced machines. Two
              different people might have the same fingerprint, but it is
              incredibly rare - and the same goes for hashes.
            </p>

            <p className={styles.postContent}>
              Blockchain is a sequence of timestamped information, of which each
              segment is called a ‘block’. In its simplest form, each block
              contains some information, a hash of the previous block, and a
              hash of the current block including the hash of the previous
              block. Although we usually stylise blockchain as a chain, one
              might also visualise it as an onion. Each layer, or block, fits
              perfectly over the previous layers, all the way to the core. If
              the shape of any individual layer changed, the onion would
              collapse.
            </p>

            <p className={styles.postContent}>
              If you alter the information in a published block, its hash must
              also change, which means the block after it is no longer linked.
              The chain is broken, and it is clear that the blocks have been
              tampered with. But what if you changed the hash of the current
              block, then quickly recalculated the hash of every subsequent
              block so that they now link together? To protect against this
              method, trusted blockchain systems have made the process of
              generating valid blocks a time and resource intensive process.
            </p>

            <p className={styles.postContent}>
              For a block to be successfully created and accepted by the
              network, its hash must start with a specific number of zeroes.
              This means blockchain miners must slightly change the contents of
              the new block, then recalculate its hash, and repeat until they
              stumble across some value that satisfies the constraints. The
              added value that makes the block valid is known as the “nonce”,
              and the huge amount of labour involved is known as the “Proof of
              Work”. The scale of difficulty in succeeding in this task not just
              for the modified block, but every block thereafter up to the
              present, effectively prevents users from altering blockchain
              history.
            </p>

            <p className={styles.postContent}>
              However, what if you were able to somehow succeed in modifying a
              block and all its subsequent chains? This is where the “consensus”
              nature of blockchain comes into play - a miner can only validate
              their version of the blockchain if the majority of the distributed
              network agrees it is valid. In this case, the thousands of other
              valid blockchain copies will prevail, and the suspect chain will
              be denied. However, what if you took over more than half of the
              globe’s blockchain computing power? Known as a “51% attack”, this
              could allow malicious users to “double spend” or else prevent new
              blocks from being added - and if the user was able to modify a
              block correctly, they could effectively overwrite history.
            </p>

            <p className={styles.postContent}>
              Since owning more than half the network is impossible in trusted
              systems like Bitcoin or Ethereum, we can guarantee that our
              blockchain is unbreakable. Blockchain is able to preserve data
              integrity so effectively because of its popularity. It may be that
              one day, hardcore miners will abandon their stations and database
              copies will dwindle. Perhaps then it may be feasible to break
              blockchain - although if the system is in such decline, there may
              be little value to be lost.
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "chelsea-chaffey": [
              "/portraits/blog-authors/chelsea-chaffey1.PNG",
              styles.authorProfile,
              "Chelsea Chaffey",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost87;
