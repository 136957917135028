import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost71 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #71" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Rue Ching Teh, UNSW Student and Software Engineer at 
              WiseTech Global
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>October 6, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
            Rue Ching Teh is a self-driven, motivated individual who has 
            dedicated herself to every opportunity she has taken. In her 
            first year at UNSW, she became a Careers subcommittee member 
            in UNSW’s Engineering Society where she empowered students to 
            discover the diverse career pathways within engineering and 
            gain insight into the industrial landscape. She then went on 
            to be an External Relations subcommittee member in WIT and is 
            currently Vice President of UNSW Digital Society as well as 
            working as a Software Engineer at WiseTech Global. Her ongoing 
            interest in technology is apparent as we talk to her about her 
            current position within the STEM industry.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              A couple of reasons, the first one was definitely family influence 
              as my sister is also in tech/STEM, and being able to see a day in 
              a life of someone in tech really helps me appreciate the tech 
              culture e.g flexible working hours, interacting with others, 
              working on solutions that's ahead of its time. I've also been 
              involved in numerous programs e.g Women for ACCESS that allowed me 
              to interact with industry representatives and see how their change 
              impacts the community.{" "}
            </p>
            <br />

            <p className={styles.question}>
              Please provide a short summary of your career journey so far:
            </p>

            <p className={styles.postContent}>
              I'm a 3rd year software engineering student at UNSW, and I'm also a 
              software engineer at WiseTechGlobal after finishing a placement with 
              the UNSW Co-Op Program. I've been involved in numerous societies 
              e.g WIT, EngSoc, DigiSoc. I was also fortunate enough to participate 
              in NCSS (National Computer Science School) as an industry mentor, as 
              well as in GPN (Girls’ Programming Network) workshops.{" "}
            </p>
            <br />

            <p className={styles.question}>
              Where do you see yourself in the future (career wise)?
            </p>

            <p className={styles.postContent}>
              I personally think I'm in the early stages of my career, and at 
              this stage I want to be in a position where I'd be able to 
              confidently help and support others whether it be through work 
              related issues or being able to confidently help someone achieve 
              their goal as a mentor. I see myself developing my technical skills 
              in software dev and then slowly branching out to the product side 
              where I'd be able to directly engage with clients and gain a 
              holistic understanding of both sides of the coin. I'm also very 
              interested in the business side of things so I hope that one day 
              I'd be able to create a product or brand where I have a lot more 
              involvement that people could find useful whether this be through 
              a project or developing something with a team.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What do you love about your career now?
            </p>

            <p className={styles.postContent}>
              What I love about the career now is the culture. I believe that in 
              tech, whilst there are definitely some improvements to be made, 
              the tech culture has its unique blend that drives forward thinking 
              through a very flexible and welcoming environment that gives people 
              the freedom to innovate, carve their own path and personality.{" "}
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase 
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              I think it’s important because tech is traditionally seen as 
              male-dominated industry, and I believe the movement to bring 
              diversity, whether this be empowering women or minorities, brings 
              changes to society's perspective and how minorities are perceived. 
              And being able to respect people irregardless of their backgrounds 
              and provide a safe and inclusive environment for people to develop 
              is important to not only the individual but improves creativity and 
              innovative thinking. I also think that as a female in STEM, it’s 
              important to help people feel safe and welcomed, and the tech industry 
              whilst is very open, can be daunting at some times because at times 
              you might not have a female support network and it feels like you're 
              in it alone, or that you feel a sense of imposter syndrome kicking 
              in.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the challenging aspects of your career?
            </p>

            <p className={styles.postContent}>
              The challenging parts of my career are definitely related to imposter 
              syndrome, we're surrounded by so many talented people and it means 
              that sometimes you discredit your abilities or how much you were able 
              to grow professionally and personally. At the same time, the tech 
              field is always evolving and sometimes it feels like your goal or your 
              perceived abilities is still a long long way away because there’s 
              always so much room for growth and to learn.{" "}
            </p>
            <br />

            <p className={styles.question}>
              How have others inspired you at different periods throughout your 
              career?
            </p>

            <p className={styles.postContent}>
              Through WIT I was able to join the empowerment program with my 
              mentor Felicity, and she has always been really supportive in 
              helping me realise my area for improvement, or to ground me and 
              provide advice in challenging or unfamiliar situations. She really 
              sets an example of where I envision myself professionally being 
              able to navigate through different situations professionally or 
              personally. At other times it’s my surroundings, be it friends, 
              work colleagues or fellow students who are always determined, and 
              at times when we're down, we would pick each other up, and that 
              inspires me to try and do my best.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students 
              who are interested in pursuing a similar career path (for 
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              In tech, problem solving and algorithmic skills are definitely 
              valuable, but at the same time, being able to think creatively 
              and be empathetic is also very important whether this is 
              interacting with others or working on a task. At WiseTech, you are 
              able to ask anyone anything, however, it’s always good to be 
              empathetic and understand how busy they must be, and then be 
              respectful about their time, and to treat each interaction or each 
              task as a valuable learning opportunity. Resilience is also very 
              important because navigating a new environment means that you'll 
              face unfamiliar concepts and roadblocks, and being able to come 
              back up and keep a growth mindset is very important to developing.
              {" "}
            </p>
            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger self 
              back in university?
            </p>

            <p className={styles.postContent}>
              I'm still in uni, but I believe in uni and with internships, it’s 
              about discovering your passions by experimenting and throwing 
              yourself in diverse and unfamiliar environments/areas.{" "}
            </p>
            <br />

            <p className={styles.question}>
              Favourite quote or motto?
            </p>

            <p className={styles.postContent}>
              The most frequent thing I say would be "I can do this, just keep 
              going" or a fancier quote I resonate with is, “Falling down is 
              part of life, getting back up is living.”{" "}
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "natalie-leroy": [
              "/portraits/blog-authors/natalie-leroy.png",
              styles.anonProfile,
              "Natalie Leroy",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost71;
