import MessengerCustomerChat from "react-messenger-customer-chat";
import React, { Component } from "react";
import { HashRouter, Route, NavLink, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "semantic-ui-css/semantic.min.css";
import "../style.css";
import GoogleAnalytics from "../config/GoogleAnalytics";

import Home from "../home/home";
import JoinUs from "../join/joinUs";
import Sponsors from "../sponsors/sponsors";
import Opportunities from "../opportunities/opportunities";
import ContactUs from "../contact/contactUs";
import MarketingContent from "../marketing-archive/content";
import OurTeam from "../team/team";
import Blog from "../blog-gallery/blog";
import Publications from "../publications/publications";
import Events from "../events/events";
import EventRecapPage from "../events/event-recaps/template";
import Podcast from "../podcast/Podcast";
import EpisodePage from "../podcast/EpisodePage";
import NotFound from "../not-found/NotFound";
import OurStory from "../ourStory/OurStory";
import Videos from "../videos/videos";

import Menu from "./menu";
import MenuBtn from "./menuBtn";
import Footer from "./footer";
import DropdownMenu from "./dropdownMenu";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      navBar: false,
      hideNav: false,
    };
    this.navClass = "navbar navbar-expand-md navbar-dark bg-custom fixed-top";
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.changeBackground = this.changeBackground.bind(this);
    this.hideNavBar = this.hideNavBar.bind(this);

    this.routes = {
      Home: ["/", "HOME"],
      "About Us": ["/about", "ABOUT US"],
      Events: ["/events", "EVENTS"],
      Opportunities: ["/opportunities", "OPPORTUNITIES"],
      Media: ["/media", "MEDIA"],
      "Join Us": ["/join-us", "JOIN US"],
    };
  }

  // change the background of the navigation bar based on scroll height
  changeBackground() {
    if (window.scrollY >= 60 || window.location.href.split("#")[1] === "/404") {
      this.setState({
        navBar: true,
      });
    } else {
      this.setState({
        navBar: false,
      });
    }
  }

  displayNavBar(route) {
    return (
      <li
        className={this.state.navBar ? "nav-item active-nav-item" : "nav-item"}
        key={route}
      >
        {/* Highlights navbar if user is on that page (red line on yellow nav bar) */}
        {this.state.navBar ? (
          <NavLink
            exact
            to={this.routes[route][0]}
            activeClassName="highlight-nav-no-drop"
          >
            {this.routes[route][1]}
          </NavLink>
        ) : (
          <NavLink
            exact
            to={this.routes[route][0]}
            activeClassName="highlight-nav-white"
          >
            {this.routes[route][1]}
          </NavLink>
        )}
      </li>
    );
  }

  // open and close menu
  handleMenuClick() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  // close the menu if the screen is being resized
  updateMenu = () => {
    if (window.innerWidth >= "950" && this.state.menuOpen) {
      this.handleMenuClick();
    }
  };

  // hide the navigation bar when a user is at the bottom of the page
  hideNavBar() {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    const loadingScreenExists = document.getElementById("loadingBackground");

    if (!!loadingScreenExists) {
      this.setState({
        hideNav: false,
      });
    } else if (bottom) {
      this.setState({
        hideNav: true,
      });
    } else {
      this.setState({
        hideNav: false,
      });
    }
  }

  // hide the messenger chat when the the loading screen is visible or user is at the bottom of the page
  hideFbChat() {
    const loadingScreenExists = document.getElementById("loadingBackground");
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (window.FB !== undefined && (!!loadingScreenExists || bottom)) {
      window.FB.CustomerChat.hide();
    } else if (window.FB !== undefined) {
      window.FB.CustomerChat.show(false);
    }
  }

  componentDidMount() {
    GoogleAnalytics();

    window.addEventListener("hashchange", () => {
      this.changeBackground();
      this.hideFbChat();
    });
    window.addEventListener("resize", this.updateMenu);
    window.addEventListener("scroll", () => {
      this.changeBackground();
      this.hideNavBar();
      this.hideFbChat();
    });
  }

  componentWillUnmount() {
    window.removeEventListener("hashchange", () => {
      this.changeBackground();
      this.hideFbChat();
    });
    window.removeEventListener("resize", this.updateMenu);
    window.removeEventListener("scroll", () => {
      this.changeBackground();
      this.hideNavBar();
      this.hideFbChat();
    });
  }

  notDropdownItem(index) {
    switch (index) {
      case 0:
      case 2:
      case 3:
      case 5:
        return true;
      default:
        return false;
    }
  }

  render() {
    return (
      <div>
        <HashRouter basename="/">
          <Menu
            open={this.state.menuOpen}
            handleMenuClick={this.handleMenuClick}
          />
          <MessengerCustomerChat
            pageId={process.env.REACT_APP_PAGE_ID}
            appId={process.env.REACT_APP_APP_ID}
            onLoad={this.hideFbChat()}
          />
          <nav
            className={
              this.state.navBar
                ? this.state.hideNav
                  ? this.navClass + " activeNav hiddenNav"
                  : this.navClass + " activeNav"
                : this.state.hideNav
                ? this.navClass + " hiddenNav"
                : this.navClass
            }
          >
            <a className="navbar-brand" href="/">
              <img
                src={
                  // ensures logo stays white when scrolling
                  window.matchMedia &&
                  window.matchMedia("(prefers-color-scheme: dark)").matches
                    ? process.env.PUBLIC_URL + "./logo-white.png"
                    : this.state.navBar ||
                      (window.matchMedia &&
                        window.matchMedia("(max-width: 950px)").matches)
                    ? process.env.PUBLIC_URL + "./logo-black.png"
                    : process.env.PUBLIC_URL + "./logo-white.png"
                }
                className="photo logo"
                alt="wit logo"
              />
            </a>
            <div className="navbar-collapse collapse w-100">
              <ul className="navbar-nav ml-auto">
                {Object.keys(this.routes).map((route, index) => {
                  return this.notDropdownItem(index) ? (
                    this.displayNavBar(route)
                  ) : (
                    <DropdownMenu
                      navbarState={this.state.navBar}
                      index={index}
                      route={route}
                      key={route}
                    />
                  );
                })}
              </ul>
            </div>
            <li className="nav-item-btn">
              <MenuBtn
                open={this.state.menuOpen}
                onClick={this.handleMenuClick}
              />
            </li>
          </nav>

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/events" component={Events} />
            <Route
              path="/event-recaps/:year/:eventNumber"
              component={EventRecapPage}
            />
            {/*When user clicks on About tab, the page will be redirected to Our Story*/}
            <Route exact path="/about">
              <Redirect to="/about/our-story" />
            </Route>
            <Route exact path="/about/our-story" component={OurStory} />
            <Route path="/about/our-team" component={OurTeam} />
            <Route exact path="/media/blog" component={Blog} />
            <Route exact path="/media" component={Blog} />
            <Route path="/media/marketing" component={MarketingContent} />
            <Route path="/join-us" component={JoinUs} />
            <Route path="/about/sponsors-affiliations" component={Sponsors} />
            <Route path="/opportunities" component={Opportunities} />
            <Route path="/about/contact-us" component={ContactUs} />
            <Route exact path="/media/podcast" component={Podcast} />
            <Route
              path="/media/podcast/:episode(\d+)"
              component={EpisodePage}
            />
            <Route path="/media/publications" component={Publications} />
            <Route exact path="/media/videos" component={Videos} />
            <Route path="/media/videos/:videoNumber(\d+)" component={Videos} />
            {Array.from({ length: 92 }, (_, index) => index + 1).map(
              (blogNo) => {
                return (
                  <Route
                    key={blogNo}
                    path={"/media/blog/" + blogNo}
                    component={
                      require(`../blog-post/blog-post-${blogNo}`).default
                    }
                  />
                );
              }
            )}
            {/*Redirect old blog links to new blog links*/}
            {Array.from({ length: 92 }, (_, index) => index + 1).map(
              (blogNo) => {
                return (
                  <Route key={blogNo} path={"/blog/" + blogNo}>
                    <Redirect to={"/media/blog/" + blogNo} />
                  </Route>
                );
              }
            )}
            {Array.from({ length: 92 }, (_, index) => index + 1).map(
              (blogNo) => {
                return (
                  <Route key={blogNo} path={"/resources/blog/" + blogNo}>
                    <Redirect to={"/media/blog/" + blogNo} />
                  </Route>
                );
              }
            )}
            <Route exact path="/blog">
              <Redirect to="/media/blog" />
            </Route>
            <Route exact path="/podcast">
              <Redirect to="/media/podcast" />
            </Route>
            <Route exact path="/publications">
              <Redirect to="/media/publications" />
            </Route>
            <Route exact path="/marketing-archive">
              <Redirect to="/media/marketing" />
            </Route>
            <Route exact path="/sponsors">
              <Redirect to="/about/sponsors-affiliations" />
            </Route>
            <Route exact path="/our-team">
              <Redirect to="/about/our-team" />
            </Route>
            <Route exact path="/contact-us">
              <Redirect to="/about/contact-us" />
            </Route>
            <Route exact path="/our-story">
              <Redirect to="/about/our-story" />
            </Route>
            {/*Error page*/}
            <Route path="/404" component={NotFound} />
            <Redirect to="/404" />
          </Switch>
        </HashRouter>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default App;
