import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";
import GoogleAnalytics from "react-ga";

class blogPost76 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #76" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Dr Rebecca Rockett - Virologist at the University of
              Sydney
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>November 3, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Throughout our WIT Crush Wednesdays, we have featured historical
              women in STEM who have made amazing contributions and advances in
              technology or contributing to defining times with technology, and
              showcase these women as role models to other women in technology.
              This week, we feature a woman in STEM who has made an impact in
              our own era, Dr Rebecca Rockett, a virologist involved in
              developing NSW’s first genomic sequencing test for COVID-19.
            </p>
            <br />

            <p className={styles.postContent}>
              Rebecca Rockett has an extensive history of working in the
              healthcare industry. From the early 2000’s she worked in The
              Australian Red Cross Blood Service where she worked as a lab
              assistant then to a technical officer of viral serology, meaning
              she explored detection of viruses. She then worked at the NSW
              State Reference Laboratory for HIV/AIDs monitoring the impact of
              the growing HIV epidemic. From there, she worked as a virologist
              in England then moved back to Australia. She later became a
              research fellow, further exploring infectious diseases. It is
              clear from her history, that Rebecca Rockett is dedicated to her
              work and well-accomplished.
            </p>
            <br />

            <p className={styles.postContent}>
              Last year, while sitting in her tent on an Australia Day camping
              trip, Dr Rebecca Rockett designed NSW’s first genomic sequencing
              test for COVID-19. An accomplished virologist at Sydney University
              and Westmead hospital, Dr Rockett’s experience analysing rockmelon
              contamination and salmonella outbreaks provided her and her team
              the expertise to trace the parentage of COVID-19 in Australia. Dr
              Rockett’s work in creating ‘genetic fingerprints’ continues to
              provide vital clues to authorities about the passage of the virus
              through the community, helping keep us all safer.
            </p>
            <br />

            <p className={styles.postContent}>
              Read more about her work in the pandemic{" "}
              <GoogleAnalytics.OutboundLink
                eventLabel="blog-post-76-here"
                to="https://www.sydney.edu.au/news-opinion/news/2020/07/10/genetic-fingerprints-of-first-covid19-cases--help-manage-pandemi.html"
                target="_blank"
              >
                here.
              </GoogleAnalytics.OutboundLink>{" "}
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "chelsea-chaffey": [
              "/portraits/blog-authors/chelsea-chaffey.png",
              styles.authorProfile,
              "Chelsea Chaffey",
              "Publications Co-Director",
            ],
            "natalie-leroy": [
              "/portraits/blog-authors/natalie-leroy.png",
              styles.authorProfile,
              "Natalie Leroy",
              "Media Director",
            ],
            "georgie-mansfield": [
              "/portraits/blog-authors/georgie2021.jpg",
              styles.authorProfile,
              "Georgie Mansfield",
              "Education Executive",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost76;
