import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost81 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #81" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              The Global Chip Shortage: Demystified
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>February 18, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Semiconductors are omnipresent - embedded in everything from your
              laptop to your toothbrush. However, a growing global shortage of
              silicon chips has caused the prices of goods to skyrocket, with no
              clear end in sight. Why are silicon chips so hard to produce, and
              why are suppliers struggling to catch up?
            </p>

            <p className={styles.postContent}>
              Made from silicon and glass, semiconductor chips control the flow
              of digital signals, and therefore information, around an
              electronic device. This is achieved by introducing impurities into
              the silicon chip to restrict or channel the flow of electrons, a
              delicate process known as silicon doping. From the first humble
              transistor made in 1947, to the tiny advanced integrated circuits
              and microprocessors we use today, engineers continue to push the
              boundaries of size and sophistication - with chips becoming more
              integral to our lives than ever.
            </p>

            <p className={styles.postContent}>
              With each circuit having a special purpose, chips have to be
              carefully designed before being manufactured. As such, the chip
              industry is largely divided into “Fabless” companies, which
              architect the chips, and “Foundries”, which produce them. Although
              large integrated manufacturers like Intel both design and produce
              their chips, the majority of businesses choose to adopt the
              Fabless model, outsourcing manufacturing to a few bottleneck
              factories. Today, the chip manufacturing industry is dominated by
              Samsung and TSMC, with Taiwan, South Korea and China accounting
              for over 85% of global foundry capacity.
            </p>

            <p className={styles.postContent}>
              Like many economic shortages, the lack of chips on the market
              ultimately comes down to an imbalance of demand and supply.
              Pre-pandemic demand for chips was already high, with the rollout
              of 5G technology, heavy investment in IoT (Internet of Things) and
              AI/Machine Learning sectors, and cryptocurrency miners largely
              soaking up semiconductor supply. US trade sanctions in 2020 caused
              Huawei and other Chinese firms to engage in stockpiling behavior,
              placing massive orders for chips before they became unavailable.
            </p>

            <p className={styles.postContent}>
              When the pandemic hit, demand for electronic devices skyrocketed.
              With more people working from isolation, consumers are upgrading
              home offices, tablets and PCs, while companies are scrambling to
              set up the computing infrastructure for remote working systems.
              Demand for cloud computing has caused big players like AWS and
              Microsoft Azure to expand their own hardware capacities. In
              addition, isolated fires in two Japanese chip plants, a
              record-breaking Taiwanese drought, and a Texas winter storm has
              left multiple chip plants unoperational, tightening the crunch.
            </p>

            <p className={styles.postContent}>
              An inflexible supply chain has turned what was once expected to be
              a short, sharp shortage into a multi-year fiasco predicted to
              continue well into 2022. A lack of competition in the oligopoly
              market has meant there is little existing excess capacity to be
              exploited. New manufacturing plants are expensive and may take up
              to three years to become operational. This has led to a sustained
              period of shortages and inflated prices for anything from laptops
              to aerospace equipment.
            </p>

            <p className={styles.postContent}>
              The car industry, which largely relies on legacy chips, is the
              worst hit by far. From calibrating fuel injections, monitoring
              tyre pressure and speedometers to smart entertainment systems,
              chips are critical to modern car production. However, many
              automobile manufacturers have suddenly found themselves at the
              bottom of the pecking order, with their early pandemic shutdowns
              and small chip market share making them a more risky choice for
              foundries to invest in. Even when factories receive their chips,
              it will take months to build them into their cars. Big
              manufacturers like Ford and General Motors have shut down factory
              plants, with BMW and Tesla leaving advanced features out of some
              models.
            </p>

            <p className={styles.postContent}>
              The Global Chip Shortage is caused by a complex array of factors,
              with no easy end in sight. While many events coincided to cause
              the rise in prices, there is no reason why such a shortage may not
              happen again - and with chips becoming more and more critical for
              daily life, it could be even more devastating. Ultimately, the
              advance of technology is limited by the hardware it operates on,
              and work to create robust supply chains may be needed to secure
              future innovations. In the meantime, maybe hold off on buying your
              first car.
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "chelsea-chaffey": [
              "/portraits/blog-authors/chelsea-chaffey1.PNG",
              styles.authorProfile,
              "Chelsea Chaffey",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost81;
