import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost78 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #78" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Parmida Borhani, Director of Solution Engineering at
              Appian
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>November 10, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              With a degree in Civil Engineering, Parmida first jumped into
              technology when she took the role of being a consultant at Appian.
              Committed to growing, developing, and challenging herself, Parmida
              transferred from the United States to Australia in 2017 where she
              progressed her career as a Solutions Engineer. Today Parmida is
              the Director of Solutions Engineering for the Asia Pacific and
              Japan Region at Appian. Parmida leads teams that tackle urgent,
              high-stake, and challenging issues. This week, we talk about where
              her career has taken her, why she loves her role, and insights
              into what it’s like to work at Appian.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              I always had an interest towards science and maths as a kid. I
              think I liked that everything had a right answer and the structure
              to problem solving. And not to mention how cool it is learning how
              the world around us works!
            </p>
            <br />

            <p className={styles.question}>
              Please provide a short summary of your career journey so far:
            </p>

            <p className={styles.postContent}>
              I studied Civil Engineering in university, but for my first job I
              made the change towards consulting at Appian. I didn’t spend very
              long as a consultant – I quickly moved into the Solution
              Engineering team. Since then, I’ve worked my way up the ranks from
              an individually contributing Associate Solution Engineer, to now
              being the Director of Solution Engineering for the APJ (Asia
              Pacific and Japan) region.
            </p>

            <br />

            <p className={styles.question}>
              Where do you see yourself in the future (career wise)?
            </p>

            <p className={styles.postContent}>
              I’m not sure to be honest. I love Appian and the Solution
              Engineering team – I’ve had some amazing mentors and still have a
              lot to learn. I’ve always dreamt of having my own business one
              day, although I’m not sure what product or service I would be
              offering! I know I enjoy helping people and staying challenged, so
              I’ll probably continue to do that.
            </p>

            <br />

            <p className={styles.question}>
              What do you love about your career now?
            </p>

            <p className={styles.postContent}>
              The people I work with! I love my team and I get joy out of
              helping them become the best that they can be, and then watching
              them help our customers with everything they’ve learned.
            </p>

            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              Diversity! Women have A LOT to offer. We are a stronger unit when
              we bring diverse backgrounds, experiences, and perspectives into a
              group. There is no reason the STEM industry needs to be a boy’s
              club! The more women we get involved, the more commonplace it
              becomes, the more other women want to get involved, and the
              healthier our environments become.
            </p>

            <br />

            <p className={styles.question}>
              What are the challenging aspects of your career?
            </p>

            <p className={styles.postContent}>
              It’s a high pressure, high stakes job. When customers reach out to
              my team, they have already tried fixing their problems and haven’t
              been able to. By the time they’ve reached out to us, it’s often
              already urgent. Companies use Appian software in a variety of
              ways, and some even run their entire businesses on Appian. If
              something goes wrong with the software, it’s my team that is on
              the front lines firefighting the issue. You have to learn how to
              stay calm, troubleshoot complex problems, and expertly communicate
              technical topics with our customers.
            </p>

            <br />

            <p className={styles.question}>
              How have others inspired you at different periods throughout your
              career?
            </p>

            <p className={styles.postContent}>
              Mentors, peers, and trusted advisors have all been there to
              inspire me at different points in my career. Whether it be
              training me on technical topics, bouncing off different ideas, or
              just being a listening ear after a long day, it’s these
              relationships that keep me going and growing.
            </p>

            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              Seek out varied experiences. Get involved in your communities.
              Find something you’re passionate about and dig into it!
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              I think I would have told myself that I didn’t need to graduate in
              4 years. I sometimes wish I took an extra year, just so I could
              get that much more variety in my experiences. I would have taken a
              wider variety of courses (maybe a minor in something) and looked
              for different internship experiences.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>

            <p className={styles.postContent}>
              Don’t accept the status quo. If you want something, speak up and
              pursue it.
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "georgie-mansfield": [
              "/portraits/blog-authors/georgie2021.jpg",
              styles.authorProfile,
              "Georgie Mansfield",
              "Education Executive",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost78;
