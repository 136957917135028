import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";
import GoogleAnalytics from "react-ga";

class blogPost91 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #91" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>NFTs - Digital Fad or Future?</h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>April 14, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Over the past year, the phrase ‘NFT’ has become commonplace within
              the digital world, spreading out from its backgrounds in digital
              art and surplanting itself as yet another tech topic that is often
              discussed but rarely understood. NFTs, or non-fungible tokens,
              were first introduced to the wider public in 2017 via
              ‘CryptoPunks’ which essentially acted as digital trading cards.
              Ten thousand of these artworks were created, and only ten thousand
              will ever exist. Although 2017 was perhaps the introduction of
              NFTs to the wider world, it was at the 2015 Ethereum developer
              conference where they were introduced to the tech and crypto
              space, a method of showcasing the abilities of the ethereum
              blockchain. Where a jpeg stores only an image, an NFT is capable
              of storing ownership details including the artwork’s ownership
              history, and artist signatures. The ‘non-fungible’ aspect of NFTs
              means they cannot ever be truly copied, just like a physical
              artwork - only one can ever exist, which stores particular
              metadata.
            </p>

            <p className={styles.postContent}>
              NFTs are synonymous now with digital art - but what, exactly,
              draws collectors to these ‘tokens’ which are, for all intents and
              purposes, entirely speculative? Art collectors typically own a
              physical piece of art, one of which nobody can own an exact copy.
              NFT collectors have the bragging rights to data on a blockchain
              and not much else. Despite this, their success is undeniable, with
              pieces selling for millions of dollars. Physical art has
              experienced a downturn in popularity over the past decade, with
              the new generation more concerned with the digital experience,
              NFT’s strong suit. The tokens can be used in a far wider setting
              than just art - almost anything digital can be turned into an NFT,
              from news articles to drawings of monkeys. Whatever the future for
              the tokens, they have been responsible for clearing a space for
              digital artists in a field nearly impossible to break into.
              Although digital artists are yet to be held in the same regard as
              their physical counterparts, there’s nothing like an NFT selling
              for 91 million to even the gap.
            </p>

            <p className={styles.postContent}>
              The increase in interest in NFTs over the past year occurred for a
              number of reasons, the largest of which likely being the
              introduction of cryptocurrency to the general public, and with it,
              blockchain. NFTs, being stored on the ethereum blockchain, became
              widely discussed during the unprecedented spike in crypto over the
              past several years. In early 2021, the sale of Mike Winkelmann’s
              artwork <i>Everydays - The First 5000 Days</i> at London’s famous
              auction house Christie's received news coverage due to the $90
              million AUD price tag attached. But what exactly is the buyer
              paying for?
            </p>

            <div className={styles.outerBox}>
              <div className={styles.innerBox}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/blog-attachments/blog-91-first-5000-days.jpeg"
                  }
                  alt="first-5000-days"
                />
                <br />
                <GoogleAnalytics.OutboundLink
                  eventLabel="blog-post-91-first-5000-days"
                  to="https://onlineonly.christies.com/s/beeple-first-5000-days/beeple-b-1981-1/112924"
                  target="_blank"
                >
                  Mike Winkelmann, <i>Everydays - The First 5000 Days</i>
                </GoogleAnalytics.OutboundLink>
                <br />
              </div>

              <div className={styles.innerBox}>
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/blog-attachments/blog-91-the-merge.jpeg"
                  }
                  alt="Pak - The Merge"
                />
                <br />
                <GoogleAnalytics.OutboundLink
                  eventLabel="blog-post-91-the-merge"
                  to="https://www.barrons.com/articles/paks-nft-artwork-the-merge-sells-for-91-8-million-01638918205"
                  target="_blank"
                >
                  PAK, <i>The Merge</i>, a collection of artworks that sold for
                  91.8 Million
                </GoogleAnalytics.OutboundLink>
                <br />
              </div>
            </div>
            <br />

            <p className={styles.postContent}>
              Ultimately, the ownership of an NFT has meaning only due to the
              token’s presence on a blockchain. This means others can see this
              ownership, which, for collectors, equals recognition and prestige
              from others in the community. Additionally, it is a way of
              boasting wealth - ultimately, any artwork selling at this price is
              largely a method of emphasizing extreme affluence.
            </p>

            <p className={styles.postContent}>
              It cannot be denied that NFTs are now firmly in the mainstream -
              but will they stay this way forever? The tokens have provided
              digital artists recognition and compensation, whilst providing a
              chance to own art for everyone, from the hobbyist to art
              collector. Their existence assisted in growing the Ethereum
              economy and public trust in crypto as a valid form of currency -
              but at what cost?
            </p>

            <p className={styles.postContent}>
              It is a commonly-spouted statistic that the energy used to trade
              and create Bitcoin annually is more than that used by the nation
              of Finland in a year. The Ethereum currency, although more
              energy-efficient than bitcoin, still uses significant power, and
              as such NFTs are incredibly power-hungry. The world’s climate
              crisis has the potential to be solved using innovative technology
              - but NFTs, although innovative, simply increase carbon emissions.
              Additionally, higher interest in NFTs means more trading - and an
              unfortunate outcome for the environment. Digital art undoubtedly
              deserves a place in our increasingly digital world - but not at
              the cost of our planet.
            </p>

            <p className={styles.postContent}>
              And so the question remains - will the popularity (and price) of
              NFTs increase, or will they cement themselves as fad of the 21st
              century? Only time will tell - but ultimately, if a sustainable
              method of creating NFTs and ensuring the security of blockchains
              is not produced, it is unlikely that the next generation will
              invest their money into such a power-hungry product, making NFTs a
              thing of the past.
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "kara-mckinney": [
              "/portraits/blog-authors/kara-mckinney.png",
              styles.authorProfile,
              "Kara McKinney",
              "Publications Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost91;
