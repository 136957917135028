import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost84 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #84" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>The Emergence of the Metaverse</h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>March 3, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Imagine a 3-dimensional virtual world where you are represented by
              avatars that can continue to exist even without your control.
              Imagine being able to trade digital assets, socialise and travel
              to different digital locations, without even leaving your room.
              This is the Metaverse - a seemingly promising future, that is also
              drawing great apprehension for the impacts that it can have on
              society. To access this world, all you need to do is to switch on
              a tech device or wear a particular VR headset, click a few buttons
              on the screen and enter!
            </p>

            <p className={styles.postContent}>
              The Metaverse was first coined by Neal Stephenson in his 1992
              novel Snow Crash to describe a world to which individuals would
              escape from their dystopian reality. The portmanteau ‘meta’ and
              ‘universe’ captures the magnificent interactions of technology
              that are turning Stephenson’s fictional concept into a reality.
            </p>

            <p className={styles.postContent}>
              The Metaverse is still in the early stages of development, and how
              far it may transcend our imagination is yet to be known. However,
              we are witnessing elements of its existence in gaming platforms
              such as Fortnite, as well as crypto-enabled marketplaces, digital
              twin technologies and more. Using a combination of augmented
              reality (AR), virtual reality (VR), blockchain and elements of
              social media, the Metaverse focuses on disintegrating the barrier
              between reality and the screen. Common features include having
              customisable avatars and a hardware infrastructure that acts as
              the access point into the Metaverse. Increasingly, blockchain is
              also used to curb the threat of cyber attacks.
            </p>

            <p className={styles.postContent}>
              Anticipated to be worth $800 billion in market value, many tech
              giants have invested heavily into bringing this promising concept
              to life. Microsoft, for example, invested a mind-boggling $70
              billion to acquire video game developer and publisher Activision
              Blizzard, to develop software and games in the Metaverse. Google
              has also demonstrated a persevering interest in AR, despite the
              failure of their AR glasses in 2014. Facebook is also adamant on
              dominating this market, making headlines in October 2021 for
              rebranding their company as ‘Meta’. They describe their vision for
              the Metaverse as,
            </p>
            <br />

            <i>
              <ol className={styles.list}>
                “...a set of virtual spaces where you can create and explore
                with other people who aren’t in the same physical space as you.
                You’ll be able to hang out with friends, work, play, learn,
                shop, create, and more.”
              </ol>
            </i>
            <br />

            <p className={styles.postContent}>
              Aside from businesses who will be reaping the gains of
              profitability, the economy will also benefit from new jobs and
              opportunities. Facebook, for example, has already created 10,000
              new jobs in Europe to support its Metaverse project. Since many
              businesses are likely to retain their hybrid work model after the
              pandemic, the Metaverse can improve the quality of remote working
              and attract more labour.
            </p>

            <p className={styles.postContent}>
              Users can also enjoy the convenience of virtually attending
              classes, meeting with faraway family members and traveling to
              destinations that would have otherwise been out of reach. For
              practitioners such as surgeons, the Metaverse can also provide a
              simulated environment to practice new techniques, improving the
              quality of healthcare received by the community. It is clear that
              the benefits of the Metaverse will continue to grow, especially
              with the ever-evolving capabilities of technology.
            </p>

            <p className={styles.postContent}>
              However, just like any technology, the Metaverse also has inherent
              flaws. The issues that we currently face, including cyberthreats,
              misuse of user information and ethical misconduct, can very much
              be present in the Metaverse. Likewise, users with malicious
              intents can also take on false identities to manipulate or
              terrorise others, leaving real-world psychological harm. Other
              social problems that can be exacerbated include: addiction,
              sensory overstimulation and detachment from reality.
            </p>

            <p className={styles.postContent}>
              The Metaverse hasn’t reached its fully-fledged state yet, and who
              knows what more can arise with its emergence. But through
              protective tools such as blockchain, and effective regulation, it
              is possible for the Metaverse to be largely free from such
              adversities. So don’t be dismayed! The Metaverse may one day
              become a force that brings our world closer together.
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "yuhan-zhou": [
              "/portraits/blog-authors/yuhan.jpg",
              styles.authorProfile,
              "Yuhan Zhou",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost84;
