import React from "react";
import styles from "../sponsors.module.css";

//getting the Nakatomi Description
const nakatomiDescription = () => {
  return (
    <>
      <p>
        Nakatomi is a multi-disciplinary, award winning technology innovation
        company. We function as an extension of businesses to design meaningful
        products and experiences using our unique perspective.
      </p>
      <p>
        We apply unlimited imaginative thinking, combined with engineering
        certainty, to solve uncommon problems. The result is high impact,
        bespoke products and solutions. Imagined, validated and executed.
      </p>
      <p>
        We’re experts in the disciplines most relevant to the future. Some of
        our best work involves helping startups launch, product strategy and
        development for a variety of businesses, introducing brands to the
        metaverse, and creating branded experiences that blow minds.
      </p>
    </>
  );
};

// Getting the mcGrathNicol description
const mcGrathNicolDescription = () => {
  return (
    <>
      McGrathNicol is a specialist Advisory and Restructuring firm. Their
      experts work with corporates, banks, law firms, private companies and
      government across all industry sectors and business sizes to deliver
      valuable advice and high-quality outcomes. McGrathNicol’s Technology team
      specialise in complex forensic investigations, time critical cybersecurity
      incident response activities and helping our clients to design strategies
      and implement frameworks to mitigate digital and information risk. To gain
      insight into the type of work they do, check out their Technology virtual
      experience at{" "}
      <a
        className={styles.link}
        href="https://www.theforage.com/show-firm-programs/oiHtSBFcPM2KnaEGJ/McGrathNicol"
        target="_blank"
        rel="noopener noreferrer"
      >
        Forage
      </a>
      .
    </>
  );
};

// Getting EY description
const eyDescription = () => {
  return (
    <>
      <p>
        Your ambition is as bold as ours. To solve the world’s toughest
        problems. To ask questions that have never been asked before. To
        innovate, to grow, to learn, to lead, to belong bringing out the best in
        yourself and in others.
      </p>
      <p>
        Here at EY, you’ll build the tech skillsets and growth mindsets to
        become the leader you want to be.
      </p>
      <p>
        We’ll provide the culture, the tech, the teams, the scale, the learning
        and the relationships for you to personalise your career.
      </p>
      <p>
        You’ll learn and innovate here while asking better questions to seek
        better answers and building a better working world. Together.
      </p>
      <p>It’s yours to build.</p>
    </>
  );
};

// Getting FDM description
const fdmDescription = () => {
  return (
    <>
      <p>
        FDM aims to provide any graduate who has a passion for tech and a drive
        to succeed with the skills, guidance, and support to launch their dream
        IT or business career. We welcome candidates from all degree backgrounds
        and universities, you don’t need a STEM degree to start a career in tech
        with us.
      </p>
      <p>
        The FDM Graduate Program provides fast-paced, specialised training,
        equipping graduates with the vital professional and technical skills to
        step into an exciting role with one of our leading international
        clients. From the moment you apply, our team really get to know you and
        establish your career goals, matching your skills and strengths to the
        stream that is the best fit for you.
      </p>
      <p>
        Led by our expert training team, the FDM Academy gets you job-ready in
        6-14 weeks, depending on the career pathway you choose. The technical
        stream leads to roles such as Software Development or Cloud Computing.
        And, with the business stream, pathways include Project Support or Risk
        and Compliance. You then begin your 2-year career journey as an FDM
        Consultant gaining exceptional hands-on experience and adding some
        leading brands to your CV.
      </p>
      <p>
        FDM is committed to your success. We provide you with ongoing support
        and guidance throughout your whole career journey. We recruit all year
        round and have start dates throughout the year, allowing you much more
        flexibility on when you can start your career.
      </p>
      <p>
        We are proud to support our diverse community. We celebrate differences
        because we want everyone to have equal opportunity to belong and grow in
        their career.
      </p>
      <p>
        Over its 30-year history FDM Group has won many awards. In 2022 FDM has
        risen 5 places to #16 on the GradConnection Top 100 Graduate Employers
        and is again one of GradAustralia's Top 100 Graduate Employers.
      </p>
    </>
  );
};

// Getting PWC description
const pwcDescription = () => {
  return (
    <>
      <p>
        In an increasingly complex world, PwC works with businesses, Government
        and the community to help Australia continue to thrive and grow.
      </p>
      <p>
        PwC Australia delivers quality in audit, assurance, consulting and tax
        services to more than 5,000 clients.
      </p>
      <p>
        We’re part of a network of firms in 158 countries with over 250,000
        people. PwC is one of the top 50 brands worldwide and PwC Australia is
        among LinkedIn’s top companies for where Australians want to work.
      </p>
      <p>
        We are bold about our commitment to diversity and inclusion, and we
        empower flexible working.
      </p>
      <p>
        Our purpose is to build trust in society and solve important problems.
      </p>
      <p>At PwC, we call this The Together Effect.</p>
    </>
  );
};

// Getting Amstelveen description
const amstelveenDescription = () => {
  return (
    <>
      <p>
        Amstelveen is a specialist provider of risk, assurance, performance, and
        technology expertise, operating across numerous business sectors. At our
        core, we work with our clients to help them make risk-informed strategic
        choices and raise the bar in risk and compliance; like by building a new
        risk strategy, performing project technology reviews, or helping them
        drive digital transformation.
      </p>
      <p>
        Our strong relationships with our clients are reflected in their
        continued trust in the delivery of our work and our rapid growth, with
        Amstelveen making AFR’s Fast Starters lists in FY18 and FY19!
      </p>
    </>
  );
};

// Getting Quantium description
const quantiumDescription = () => {
  return (
    <>
      <p>Power your possibilities at Quantium</p>
      <p>
        Quantium is a global leader in the application of data analytics and
        engineering, helping our clients solve their most important problems
        using data. We have a track record of innovation and combining the best
        of human and artificial intelligence to power possibilities for
        individuals, organisations and society and we're proud of it!
      </p>
    </>
  );
};

// Getting Google description
const googleDescription = () => {
  return (
    <>
      <p>
        Larry Page and Sergey Brin founded Google in September 1998 with a
        mission “to organise the world’s information and make it universally
        accessible and useful”. Since then, Google has grown to more than 130,
        000 employees worldwide, with a wide range of popular products and
        platforms like Search, Maps, Cloud, Ads, Gmail, Android, the Assistant,
        Made by Google devices and YouTube.
      </p>
    </>
  );
};

// Getting IMC description
const imcDescription = () => {
  return (
    <>
      <p>
        IMC is a leading global trading firm with technology - and individuality
        - at its heart. With offices in Amsterdam, Chicago and Sydney, you'll
        experience our truly global culture. A culture of openness, idea sharing
        and collaboration. Whether it's trading, data, software or hardware,
        we're constantly pushing ourselves and our systems further; challenging,
        adapting and redesigning to create world-class solutions that can
        outsmart the competition. It's in these collaborations, with incredibly
        fast feedback that the magic happens. Operating at this extremely high
        level gives you the skills that will form the foundations of your
        career.
      </p>
    </>
  );
};

// Getting Jane Street description
const janeStreetDescription = () => {
  return (
    <>
      <p>
        Jane Street is a proprietary trading firm that operates around the clock
        and around the globe focusing primarily on equities and equity
        derivatives. Founded in 2000, Jane Street has offices in New York,
        London, Amsterdam and Hong Kong. The environment at Jane Street is open,
        informal, intellectual and fun. You can wear a t-shirt and jeans to the
        office every day, the kitchen is stocked, and discussions are always
        lively. Teaching and learning are central activities through classes,
        mentoring and discussion
      </p>
    </>
  );
};

// Getting Blackbird description
const blackbirdDescription = () => {
  return (
    <>
      <p>
        Blackbird is a venture capital firm whose mission is to invest in wild
        hearts, with the wildest ideas, right at the beginning. We do this by
        raising venture capital funds, investing in the best startup companies
        and helping them succeed. We have a portfolio worth over $10B of more
        than 90 companies, including some of the most successful Australian and
        New Zealand startups such as Canva, Zoox, SafetyCulture and Culture Amp.
      </p>
    </>
  );
};

// Getting Palantir description
const palantirDescription = () => {
  return (
    <>
      <p>
        Problem solving is core to Palantir’s mission. Today’s critical
        institutions don’t just protect public safety or provide vital goods and
        services – they also deal with astonishing amounts of data. At Palantir,
        we build software that lets organisations integrate and analyse their
        data to solve problems they couldn’t before. For example, we’re helping
        our customers investigate and prosecute financial fraud rings, offer
        more credit to retail banking customers, manufacture products faster and
        more safely, and fight violent crime in the world’s great cities. And
        we’re just getting started.
      </p>
    </>
  );
};

// Getting Appian description
const appianDescription = () => {
  return (
    <>
      <p>
        Appian helps organisations build apps and workflows rapidly, with a
        low-code automation platform. Combining people, technologies, and data
        in a single workflow, Appian can help companies maximise their resources
        and improve business results. Our employees create opportunities to
        drive hands-on impact both with our customers and throughout the
        organisation, which creates an environment where meaningful work is met
        with career growth and opportunity.
      </p>
    </>
  );
};

// Getting Akuna description
const akunaDescription = () => {
  return (
    <>
      <p>
        Akuna Capital is an innovative trading firm with a strong focus on
        collaboration, cutting-edge technology, data driven solutions and
        automation. We specialise in providing liquidity as an options market
        maker – meaning we are committed to providing competitive quotes that we
        are willing to both buy and sell. To do this successfully we design and
        implement our own low latency technologies, trading strategies and
        mathematical models. At Akuna we have a flat structure, where the best
        idea wins.
      </p>
      <p>
        Our Founding Partners, including our CEO Andrew Killion, first
        conceptualised Akuna in their hometown of Sydney. They opened our first
        office in 2011 in Chicago, the heart of the derivatives & options
        industry. Akuna then expanded to Sydney in 2018 to be the centre of our
        Asian trading operations, and in 2021 moved to our new Barangaroo
        waterfront office. We are currently trading HK, Korea, cryptocurrencies
        and US night markets and are excited for expansions into all the major
        Asian exchanges. We also have offices in Shanghai and Boston.
      </p>
      <p>
        At Akuna, we believe that the people are the centre of everything we do.
        Our Sydney office has a start-up feel with less than 200 people, which
        means you really get to know the team! We run social poker competitions,
        office happy hours, have a fully stacked breakfast and snack room, host
        social events, go on company trips, have social clubs, and offer great
        training with our Akuna University. We are expanding rapidly and looking
        for the best talent to join us on this journey. If you enjoy being part
        of smart, driven teams with real challenges to solve- this could be the
        place for you!
      </p>
    </>
  );
};

// Getting place holder description
const placeHolderDescription = () => {
  return (
    <>
      <p>
        More information will be added shortly! Check out the above website for
        now for more information about our sponsor!
      </p>
    </>
  );
};

const sponsors = {
  // name, website, img, type, description

  Airwallex: [
    "Airwallex",
    "https://www.airwallex.com/au",
    "Airwallex.png",
    "GOLD",
    placeHolderDescription(),
  ],
  Akuna: [
    "Akuna",
    "https://akunacapital.com/",
    "Akuna.png",
    "SILVER",
    akunaDescription(),
  ],
  Amazon: [
    "Amazon",
    "https://www.amazon.com.au",
    "amazon.png",
    "DIAMOND",
    placeHolderDescription(),
  ],
  Atlassian: [
    "Atlassian",
    "https://www.atlassian.com/au-en",
    "atlassian-major.png",
    "DIAMOND",
    placeHolderDescription(),
  ],
  Appian: [
    "Appian",
    "https://appian.com/",
    "appian_2021.jpg",
    "GOLD",
    appianDescription(),
  ],
  Amstelveen: [
    "Amstelveen",
    "https://amstelveen.com/",
    "Amstelveen_notchecked.png",
    "BRONZE",
    amstelveenDescription(),
  ],
  Blackbird: [
    "Blackbird",
    "https://blackbird.vc/",
    "blackbird.png",
    "GOLD",
    blackbirdDescription(),
  ],
  CISCO: [
    "CISCO",
    "https://www.cisco.com/c/en_au/index.html",
    "CISCO-diamond.png",
    "DIAMOND",
    placeHolderDescription(),
  ],
  Commonwealth: [
    "Commonwealth Bank (CBA)",
    "https://www.commbank.com.au/about-us/careers.html",
    "cba.jpg",
    "SILVER",
    placeHolderDescription(),
  ],
  EY: ["EY", "https://www.ey.com/en_au", "ey.png", "SILVER", eyDescription()],
  Fdm: [
    "FDM Group",
    "https://www.fdmgroup.com/en-au/au-home/",
    "fdm-group.png",
    "SILVER",
    fdmDescription(),
  ],
  Google: [
    "Google",
    "https://careers.google.com/",
    "google-major.png",
    "GOLD",
    googleDescription(),
  ],
  Honeywell: [
    "Honeywell,",
    "https://www.honeywell.com/us/en",
    "honeywell.png",
    "GOLD",
    placeHolderDescription(),
  ],
  IMC: ["IMC", "https://www.imc.com/ap/", "IMC.png", "GOLD", imcDescription()],
  JaneStreet: [
    "JaneStreet",
    "https://www.janestreet.com/",
    "jane-street.png",
    "GOLD",
    janeStreetDescription(),
  ],
  Macquarie: [
    "Microsoft",
    "https://www.macquarie.com.au/",
    "macquarie.png",
    "GOLD",
    placeHolderDescription(),
  ],
  McGrathNicol: [
    "McGrathNicol",
    "https://www.mcgrathnicol.com/",
    "mcgrathnicol-major.png",
    "GOLD",
    mcGrathNicolDescription(),
  ],
  McKinsey: [
    "McKinsey",
    "https://www.mckinsey.com/",
    "mckinsey.png",
    "SILVER",
    placeHolderDescription(),
  ],
  Microsoft: [
    "Microsoft",
    "https://www.microsoft.com/en-au",
    "microsoft_notchecked.png",
    "GOLD",
    placeHolderDescription(),
  ],
  Nakatomi: [
    "Nakatomi",
    "https://nakatomi.com/",
    "Nakatomi.png",
    "DIAMOND",
    nakatomiDescription(),
  ],
  Palantir: [
    "Palantir",
    "https://www.palantir.com/",
    "palantir-support.png",
    "GOLD",
    palantirDescription(),
  ],
  Pwc: [
    "Pwc",
    "https://www.pwc.com.au/",
    "pwc-logo-png-transparent.png",
    "SILVER",
    pwcDescription(),
  ],
  Quantium: [
    "Quantium",
    "https://quantium.com/",
    "quantium-major.png",
    "GOLD",
    quantiumDescription(),
  ],
  Telstra: [
    "Telstra",
    "https://www.telstra.com.au/",
    "telstra.png",
    "GOLD",
    placeHolderDescription(),
  ],
  WooliesX: [
    "WooliesX",
    "https://wooliesx.com.au/",
    "WooliesX.png",
    "GOLD",
    placeHolderDescription(),
  ],
};

const affiliations = {
  // website, img
  Arc: ["https://www.arc.unsw.edu.au/", "arc.png"],
  "Business School": [
    "https://www.unsw.edu.au/business/",
    "business_school_transparent.png",
  ],
  "Engineering Faculty": ["https://www.unsw.edu.au/engineering/", "eng.png"],
};

const partnerships = {
  // name, website, img
  "Clay Sydney": ["https://claysydney.com/", "claysydney.png"],
  "Escape Hunt": ["https://escapehunt.com/", "escapehunt.png"],
  "Pinot & Picasso": ["https://www.pinotandpicasso.com.au", "pinot.png"],
  "Side Bar": ["https://sidebarsydney.com.au/", "sidebar.png"],
  "Virtual Room": ["https://sydney.virtual-room.com/", "virtualroom.png"],
};
export { sponsors, affiliations, partnerships };
