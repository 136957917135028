import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost89 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #89" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Insights Unraveled: The Importance of Innovation
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>March 31, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Since the onset of COVID-19, many businesses have been pressured
              to produce innovative solutions to cut costs, improve efficiency
              and overcome modern challenges. The words ‘innovation’ and
              ‘creativity’ are commonly thrown around business conversations,
              but many still fail to completely understand their difference and
              inherent complexity. Today, we will explore what innovation is,
              why it is important and how we can approach it using the Double
              Diamond framework.
            </p>

            <p className={styles.postContent}>
              Years ago, the world was different to what we know today. Imagine
              having to rent DVDs to watch a movie, or going to the library to
              search for even the simplest of concepts. From online streaming
              services to search engines, nothing would have happened without
              innovation and entrepreneurial spirit. By definition, innovation
              is a process involving the adoption or modification of business
              models to create value for stakeholders. For businesses,
              innovation allows them to take advantage of new technologies to
              grow and remain competitive in the market. For consumers,
              innovation allows them to access new and exciting goods and
              services in the market and experience life to a higher material
              standard. But what happens when businesses fail to innovate? Or
              fail to <i>successfully</i> innovate?
            </p>

            <p className={styles.postContent}>
              Before Netflix, HBO Go, Amazon Prime and other powerhouses,
              Blockbuster was the go-to place and top video-rental company in
              the ‘90s, with a simple business strategy of renting DVDs and
              charging late fees. Lacking both dynamism and adaptability to new
              technology, Blockbuster chose to dismiss investments in new
              technology, even choosing not to buy Netflix! Instead they
              doggedly pursued their tried and tested business model in the hope
              that customer loyalty and nostalgia would see them through. Flash
              forward to the present, Netflix has completely stolen the show,
              leaving Blockbuster… busted.
            </p>

            <p className={styles.postContent}>
              So now we learn from history the danger of refusing innovation,
              but are all innovation efforts successful? Let’s take a look at
              Google’s unforgettable launch of Google Glasses back in May 2014.
              Relying on voice recognition and user motion, this product enables
              users to conduct Google Search, use Google Maps, take photos and
              see pop-up reminders when the glasses detect a particular object.
              But despite these impressive innovations advancing our perception
              of glasses, when Google Glasses entered the market, it completely
              flopped.
            </p>

            <p className={styles.postContent}>
              Innovation should create value for the consumer and produce a
              solution to the pains and gains in the jobs that the target
              audience needs to do. In the case of Google Glasses, it lacked
              clear functionality and triggered concern over health & safety
              issues, heating issues and privacy invasion through unconsented
              filming. Additionally, it only had a battery life of four hours,
              had limited language recognition and cost consumers more than
              AU$1000. Not only did Google Glasses fail to address the needs of
              their target market, their product created more pain points for
              users. In total, Google lost a whopping $895 million in its
              moon-shot projects, including Google Glasses. This precisely
              exemplifies the importance of developing stakeholder profiles,
              value proposition canvases and frequently engaging with
              stakeholder feedback to understand how value can be better
              created.
            </p>

            <p className={styles.postContent}>
              To understand the structure of the innovation process, let's use
              the Double Diamond framework, which has four distinct phases:
              discover, define, develop and deliver. As the previous Design
              Council product designer, Jonathan Ball had once said,{" "}
              <i>
                “I think you can use the Double Diamond to tell all sorts of
                design stories in really helpful ways. Part of its beauty is its
                simplicity.”
              </i>
            </p>

            <ol> 
              <li className={styles.list}>Discover</li>
              <p className={styles.postContent}>
                In this phase, a diverse team would come together to divergently
                research the scope of the issue. Tools such as PESTLE or the
                root cause analysis can be used to help break it down.
              </p>
              <li className={styles.list}>Define</li>
              <p className={styles.postContent}>
                The team would then need to convergently focus on a key root of
                a problem, clearly defining its context and the values they want
                to create for stakeholders.
              </p>
              <li className={styles.list}>Develop</li>
              <p className={styles.postContent}>
                This phase involves divergently brainstorming solutions and
                developing prototypes that stakeholders will comment on.
                Feedback should be applied to develop a better prototype.
              </p>
              <li className={styles.list}>Deliver</li>
              <p className={styles.postContent}>
                This stage requires strong presentation and communication skills
                to pitch the solution to a panel of judges.
              </p>
            </ol>
            <p className={styles.postContent}>
              Whilst these phases seem simple, it does require the team to be
              united by a strong strategy, a collaborative motive and a diverse
              set of skills and backgrounds. This is particularly important to
              ensure that members are motivated to follow through the process.
              Diversity is also another key factor as it creates opportunities
              for creative abrasion, which helps to generate new ideas.
              Nevertheless, these are only some of the few underlying
              characteristics driving successful innovation.
            </p>

            <p className={styles.postContent}>
              If innovation is a game, it is certainly chess. If you only defend
              yourself, or worse still, don’t move at all, you will lose to the
              countdown of time. Taking the risk to grow, learn, and innovate is
              what will bring your team closer to success.
            </p>
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "yuhan-zhou": [
              "/portraits/blog-authors/yuhan.jpg",
              styles.authorProfile,
              "Yuhan Zhou",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost89;
