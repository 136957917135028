import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost79 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #79" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Rachel Bamberg, Cybersecurity Consultant at EY
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>November 17, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Rachel Bamberg is a Cybersecurity Consultant at EY, where she
              helps Australia’s largest banks and insurers implement the latest
              security and risk standards, while developing specialist expertise
              in cyber risk, governance and compliance. With a Masters in
              International Security, and working in London and Sydney, Rachel
              has taken up multiple projects and opportunities to learn new
              skills, promote diversity, and improve female representation in
              STEM. This week, we talk to Rachel about her passion and journey
              in cybersecurity, and the advice she gives to aspiring women in
              STEM.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              My interest in STEM was sparked many years ago by my interest in
              Dystopian Fiction and how our lives could become linked with
              technology with negative consequences. Whilst I still love Sci Fi,
              I am now more interested in how we can use technology to enhance
              our lives and I remain perplexed by shifts in smart cities such as
              Seoul where you can shop for groceries in Virtual Reality
              supermarkets!
            </p>
            <br />

            <p className={styles.question}>
              Please provide a short summary of your career journey so far:
            </p>

            <p className={styles.postContent}>
              I have worked in a variety of client-facing roles in London. I
              moved to Sydney in February 2019 and I joined Ernst and Young as a
              Cybersecurity Consultant in September 2019. I was working
              part-time whilst studying for my Masters in International Security
              from USyd and once I graduated, I took up a full time role. I have
              been working in Cybersecurity since and learning a lot!
            </p>

            <br />

            <p className={styles.question}>
              What do you love about your career now?
            </p>

            <p className={styles.postContent}>
              I love the diversity of projects I am continually exposed to in
              Consulting - every few months there is the opportunity to work
              with different clients, different internal stakeholders and learn
              new skills. I also really enjoy working with different people
              across EY, this has given me great exposure to other areas of the
              business and enabled me to develop a good understanding of
              professional services.
            </p>

            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              I strongly believe that you can't be what you can't see and women
              represent just 28% of the STEM workforce. If girls can't see the
              opportunities that are open to them or are not made aware of the
              numerous careers they could pursue in the STEM field, we will
              never shift this inequity.
            </p>

            <br />

            <p className={styles.question}>
              What are the challenging aspects of your career?
            </p>

            <p className={styles.postContent}>
              Cybersecurity is a very broad field – finding an area I wanted to
              build my knowledge and skills in was initially challenging but
              comes with exposure to different areas and also time. Whilst some
              of my work is technical, my knowledge is always evolving and even
              if I don’t know the answer to something, the great thing about
              working at EY is that there is always someone who does. Generally,
              working at a large professional services firm can also be
              challenging but you learn to be confident in your abilities and
              prepared to drive your own career.
            </p>

            <br />

            <p className={styles.question}>
              How have others inspired you at different periods throughout your
              career?
            </p>

            <p className={styles.postContent}>
              The leadership team at EY is incredibly supportive and creates the
              opportunity for people at any rank to pursue their own ideas and
              vision for the team. This has inspired me to bring people together
              and connect in unique ways, from clay making classes to outdoor
              food festivals, I've always felt encouraged to put my ideas into
              action.
            </p>

            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              Building out your organisational skills and attention to detail
              are really critical as a consultant since you are often
              responsible for creating client facing deliverables and
              maintaining our high standards. From a "soft" skill perspective, I
              would encourage anyone to read "Emotional Intelligence" by Daniel
              Goleman - understanding yourself, how you work best and when will
              place you in good stead for delivering great work. Being
              comfortable with PowerPoint, Excel, Visio, Power Bi will place you
              in good stead too. A lot of what you will learn will be whilst
              working so don't stress too much!
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              Take advantage of the huge amount of extra-curricular activities
              on offer - they are a great way to demonstrate early leadership
              skills, initiative and meet lots of new people! Also, if you’re
              curious about Cybersecurity but don’t know what a career in this
              space could look like, don’t be afraid to connect with people on
              LinkedIn and ask them for a coffee or a 15 min chat. We notice the
              people who connect with us and take the time to follow up. There
              are so many opportunities at EY – part time roles which balance
              work and study, internships, graduate positions etc. We’ve all
              been Graduates at some point so don’t be afraid to be bold and
              find out more directly from people working in the field.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>

            <p className={styles.postContent}>
              "We are what we repeatedly do. Excellence, then, is not an act,
              but a habit" (Aristotle)
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "georgie-mansfield": [
              "/portraits/blog-authors/georgie2021.jpg",
              styles.authorProfile,
              "Georgie Mansfield",
              "Education Executive",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost79;
