import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost83 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #83" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Yiwen Li, Master of IT Student at UNSW
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>March 2, 2022</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Aspiring to further explore front-end web development, Yiwen sees
              technology as an integral part of her life. She uses her skills to
              pursue innovation and creativity, whilst taking on new challenges
              that drive personal growth. After completing a Bachelor of
              Software Application at the Chengdu University of Information
              Technology, Yiwen is now a second year Master of IT student at
              UNSW. This week, we will hear about how she balances work and
              study, why she recommends STEM as a career, and the top tips she
              has for fellow students.
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              I love the field of STEM because it helps me become a better, and
              more adaptable person. The world is continually changing and the
              demand for tech skills will continue to grow. With my tech skills,
              I am able to work on personal projects, allowing me to embrace the
              freedom of creativity. When you build something yourself, for
              example a website, there is a huge feeling of achievement.
            </p>
            <br />

            <p className={styles.question}>
              Tell me about your career journey so far, and some of your most
              challenging, proud or memorable moments.
            </p>

            <p className={styles.postContent}>
              I’ve had cross-cultural experiences working in both China and
              Australia. In Chengdu China, I worked as a Data Analyst Assistant
              where I used my tech skills to analyse the operational logistics
              of the Taxi Industry in New York City. I also had experience
              working as a Laboratory Assistant in the Nuclear Energy Industry,
              assisting other researchers with their preparatory work. In
              Australia, I am currently interning at a Startup Company as a
              front-end web developer.
            </p>
            <p className={styles.postContent}>
              After working in both countries, I have respect for both cultures.
              In China, there is an emphasis on competition and result-oriented
              growth, while in Australia there is an emphasis on teamwork and
              personal growth. From both sides, my experiences have shaped me to
              become a more resilient and versatile person.
            </p>
            <br />

            <p className={styles.question}>
              Who has inspired you the most in your career?
            </p>

            <p className={styles.postContent}>
              My inspiration is Cheng Hao. He is my mentor and another
              postgraduate student at UNSW. I look up to him because even though
              he sometimes takes twice as long to understand a concept, he is
              never afraid to ask for help or express his ideas. He prioritises
              his learning and always strives to understand concepts until he
              can solve a problem by himself.
            </p>
            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              Don’t be afraid to join STEM. The more you learn and understand a
              concept, the more confident you will be. Do not be bogged down by
              other people’s perception on how difficult something may be,
              instead you should try it yourself.
            </p>
            <p className={styles.postContent}>
              From my experience, the field of technology is very male
              dominated, and it is sometimes very hard to ask for help as a
              female. However, what I found is that if you do not ask for help,
              it is even more difficult for you to learn and improve. I was very
              fortunate to be able to join the Master's Degree at UNSW. The
              environment is very community-focused and supportive. Overtime,
              I’ve gained a lot of confidence in myself as well, but the key in
              achieving this is to not be scared of STEM and know that ‘stupid’
              questions do not exist.
            </p>
            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              As a front-end web developer, having technical skills such as
              React, HTML, Node.js, Git, Python, SQL and Cloud Service (AWS) is
              very helpful. However soft skills such as having good
              communication and leadership are just as important because you
              will always be working in a team. Most importantly, you need to
              have the right attitude and be willing to learn new things.
            </p>
            <br />

            <p className={styles.question}>
              What motivates you to get up in the morning every day?
            </p>

            <p className={styles.postContent}>
              Every morning when I wake up, it is a fresh new day. The things
              I'm going to do will make today even better than yesterday. There
              are many simple activities that I appreciate, such as eating lunch
              with friends, learning new skills or trying something that I have
              never done before. Every day is an opportunity to be more
              independent and push the boundaries of your competency.
            </p>

            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              Firstly, the key to balancing your work and studies is to not
              procrastinate and waste time. It is essential that you set goals
              and commit to the tasks that you have signed yourself up to.
              Secondly, it is very important to pay attention and maintain your
              network, because most likely it will be your peers who will help
              you through your career journey. Finally, having the correct
              mindset will motivate you to be responsible for your work.
              Especially when you are in tech, you should always dedicate time
              to thoroughly check your work.
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>
            <p className={styles.postContent}>Be brave, be active.</p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "yuhan-zhou": [
              "/portraits/blog-authors/yuhan.jpg",
              styles.authorProfile,
              "Yuhan Zhou",
              "Publications Co-Director",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost83;
