import React, { Component } from "react";
import ".././style.css";
import styles from "./blog-post.module.css";
import AuthorCard from "./authorCard";
import PageHeader from "../components/header";
import ShareBtns from "./ShareBtns";

class blogPost74 extends Component {
  //start webpage at the top
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        {/* Cover Photo */}
        <PageHeader imgUrl="/headers/blog-header.jpg" title="Blog Post #74" />
        {/*End of Header*/}

        {/*Start of blog post*/}
        <div className={styles.blogContent}>
          <div>
            <h2 className={styles.blogTitle}>
              Introducing Joanna Damianakis, Business Analyst and Business
              Intelligence Consultant at FDM Group
            </h2>
            <div className={styles.blogDetails}>
              <span className={styles.date}>October 27, 2021</span>
              <span>
                <ShareBtns />
              </span>
            </div>
          </div>

          <div className={styles.post}>
            <p className={styles.postContent}>
              Joanna Damianakis has long been fascinated by the highly dynamic,
              ever changing nature of STEM, and is constantly searching for
              diverse opportunities that enable her to learn and impart her
              knowledge to like minded individuals. She currently works as a
              Business Analysis and Business Intelligence Consultant at FDM
              Group, where she is able to work towards her interest in Business
              Analytics. A fresh UNSW alumni, she graduated with a double degree
              in Commerce and Information Systems in 2020, during which she was
              able to develop this interest. Read on to discover more about
              Joanna’s journey and her goals for the future!
            </p>
            <br />

            <p className={styles.question}>
              What sparked your interest to join the field of STEM?
            </p>

            <p className={styles.postContent}>
              I started being interested in STEM when I learned that so many
              aspects of STEM industries that we rely on today didn’t exist 20
              years ago. At the time I wasn’t very excited by the career paths
              that we were told to go into, such as Law, Medicine or Accounting.
              But I loved the fact that in STEM, you create something new, and
              that my dream job just may not exist yet!
            </p>
            <br />

            <p className={styles.question}>
              Please provide a short summary of your career journey so far:
            </p>

            <p className={styles.postContent}>
              When I finished high school, I started a Dual Degree of Commerce
              and Information Systems at UNSW, being undecided as to whether I
              wanted to pursue Finance or Information Systems. When I reached
              the end of my degree, I had realised I wanted to pursue Business
              Analytics because I had enjoyed group projects the most (even
              though they made me want to pull my hair out at times).
            </p>
            <p className={styles.postContent}>
              I was lucky enough to get recruited at FDM Group as a Business
              Analyst/Business Intelligence Consultant. I completed their
              rigorous training program and came out an accredited Business
              Analyst. I was initially placed as a Project Support Officer for
              Aruma, which is Australia’s largest disability service provider.
              But after my time at the project came to an end, FDM placed me
              with KPMG’s Salesforce team where I am now working as a Salesforce
              Application Consultant.
            </p>
            <br />

            <p className={styles.question}>
              Where do you see yourself in the future (career wise)?
            </p>

            <p className={styles.postContent}>
              My career up until now has shown me that I will likely change my
              mind about where I want my career to go a few times! But for now,
              I want to see myself working towards Project Management or even
              Programme Management for large systems. But my career is still in
              its early stages, so I know I may end up doing something
              completely different!
            </p>

            <br />

            <p className={styles.question}>
              What do you love about your career now?
            </p>

            <p className={styles.postContent}>
              I love that my career has led me to work in industries that I
              would never have even considered to work within when I was a
              student. Working with a non-profit organisation has meant that I
              know that the projects that I work on with them have made a real
              impact on the people that they support.
            </p>

            <br />

            <p className={styles.question}>
              Why do you think it is important to empower women and increase
              their involvement in the STEM industry?
            </p>

            <p className={styles.postContent}>
              I think that the STEM industry has always been one where women
              have been hesitant to enter as a career, but a lot of this is
              because they would be a minority within their workplace.
              Encouraging more women to join STEM industries would normalise
              women being in these roles and remove that feeling of alienation
              that may be causing less women to enter the field.
            </p>

            <p className={styles.postContent}>
              I also believe that the STEM industries benefit in having a more
              diverse group of people working within them. Having a diverse team
              that accurately reflects the diversity of the world as it is means
              that the outcomes of STEM projects benefit the most people.
            </p>
            <br />

            <p className={styles.question}>
              What are the challenging aspects of your career?
            </p>

            <p className={styles.postContent}>
              I think the most challenging aspect of moving into IT for me has
              been that there is never a standard role. Every role I would apply
              for had different expectations of applicants – some expected a
              computer science degree for admin work! So it has been hard
              finding roles that are the right fit for me.
            </p>

            <br />

            <p className={styles.question}>
              How have others inspired you at different periods throughout your
              career?
            </p>

            <p className={styles.postContent}>
              A lot of times in the last few years, I have felt like I may be
              underqualified or not skilled enough for many of the roles I have
              applied for and have been tempted to veer away from STEM out of
              fear that I am not cut out for it. But I work at FDM with so many
              other consultants who went into the same field as me with no prior
              STEM education and are fantastic at what they do. They have all
              inspired me to keep going and to pursue roles and challenges even
              if I think they are out of my comfort zone.
            </p>

            <br />

            <p className={styles.question}>
              What are the hard and soft skills that you would advise students
              who are interested in pursuing a similar career path (for
              internship/graduate role) to upskill on?
            </p>

            <p className={styles.postContent}>
              Soft skills are potentially more valuable in the role than
              technical skills, as most work is done in teams. Being able to
              communicate, self-organise and work with others well is so
              important in this field, as you are almost never going to be a
              one-person show!
            </p>

            <p className={styles.postContent}>
              In terms of hard skills, I think that learning management
              techniques and styles is very important no matter what field of
              STEM you end up in. Programming languages, design techniques and
              methodologies change all the time, but being able to see the
              bigger picture will always be valuable.{" "}
            </p>
            <br />

            <p className={styles.question}>
              What is one piece of advice that you would give to your younger
              self back in university?
            </p>

            <p className={styles.postContent}>
              I would probably advise myself to enjoy it more! You don’t realise
              how much you miss student life until you start working 9-5!
            </p>
            <br />

            <p className={styles.question}>Favourite quote or motto?</p>

            <p className={styles.postContent}>
              Faster alone, further together{" "}
              <span role="img" aria-label="smiling-face">
                😊
              </span>
            </p>
            <br />
          </div>
        </div>
        {/*for the blog post author*/}
        <AuthorCard
          authors={{
            "therese-nguyen": [
              "/portraits/blog-authors/therese-nguyen.png",
              styles.anonProfile,
              "Therese Nguyen",
              "Education Team",
            ],
          }}
        />
        {/*End of blog posts*/}
      </div>
    );
  }
}
export default blogPost74;
